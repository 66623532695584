








import { IPanelCenter, TileOptions } from "@/services/devices";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { hubs } from "../services/hubs/hub.service";
import WetmapOverlay from "./WetmapOverlay";
import Particle from "./Particle";
import { WetMapFlags } from "./RendererModels";

export class ImageDefinition {
  src: any;
  options: ImageOptions;
}
export class ImageDefinitionForCanvas extends ImageDefinition {
  image: HTMLImageElement;
}
export class ImageOptions {
  row: number;
  col: number;
  toRow: number;
  toCol: number;
}
class CanvasGrid {
  /**
   *
   */
  constructor() {
    this.masks = [];
  }
  vueCanvas: CanvasRenderingContext2D;
  maskCanvas: CanvasRenderingContext2D;
  masks: ImageDefinitionForCanvas[];

  public clearCanvas(zoomFactor: number, offset: any) {
    this.vueCanvas.clearRect(
      0 - offset.x,
      0 - offset.y,
      this.vueCanvas.canvas.width * (1 / zoomFactor),
      this.vueCanvas.canvas.height * (1 / zoomFactor),
    );
  }
}

let canvasGrid = new CanvasGrid();

@Component
export default class SquaredMap extends Vue {
  rectWidth: number = 200;
  frame: any = null;
  panels: PanelModel[] = [];
  element: HTMLCanvasElement;
  overlay: HTMLCanvasElement;
  data: Array<number> = [];
  centers: Array<Array<number>> = [];
  timeline: Array<TimelinePoint> = [];
  lastHover: string = "";
  baseOffset: number = 20;
  backgroundImage: ImageDefinitionForCanvas = {} as ImageDefinitionForCanvas;
  devices: any[] = [];
  @Prop({ default: "" }) background: string;
  @Prop({ default: () => ({ width: 100, height: 100 }) })
  backgroundOptions: any;
  @Prop({ default: () => [] }) panelCenters: IPanelCenter[];
  debugCenters: IPanelCenter[] = [];
  @Prop({ default: true }) realtimeEnabled: boolean;
  @Prop({ default: () => new WetMapFlags() }) wetmapflags: WetMapFlags;

  @Prop({ default: 100 }) max: number;
  @Prop({
    default: () => ({ width: 10, height: 10, border: 2 } as TileOptions),
  })
  tileOptions: TileOptions;
  @Prop({ default: 1 }) zoom: number;
  @Prop({ default: () => [] }) inlineData: number[];
  @Prop({ default: () => [] }) masks: ImageDefinition[];
  @Prop({ default: "whitesmoke" }) maskColor: string;
  @Prop({ default: 1000 }) width: number;
  @Prop({ default: 600 }) height: number;
  @Prop({ default: 15 }) radius: number;
  @Prop({ default: 10 }) blur: number;
  @Prop({ default: "1" }) board: string;
  @Prop({ default: 0 }) offsetX: number;
  @Prop({ default: 0 }) offsetY: number;
  @Prop({
    default: () => ({
      blue: 0.4,
      cyan: 0.6,
      lime: 0.7,
      yellow: 0.8,
      red: 1.0,
    }),
  })
  gradientOptions: any;

  @Watch("zoom") updateZoom() {
    this.frame =
      this.frame ||
      window.requestAnimationFrame(() => {
        this.updateMask();
        this.drawRect();
      });
  }
  @Watch("background") async backgroundUpdate() {
    if (this.background) {
      var backgroundDefinition: ImageDefinitionForCanvas = {
        src: this.background,
        options: { row: 0, col: 0, toRow: 100, toCol: 100 },
        image: new Image(),
      };
      this.backgroundImage = await this.preloadSprite(backgroundDefinition);
      this.frame =
        this.frame ||
        window.requestAnimationFrame(() => {
          this.updateMask();
          this.drawRect();
        });
    }
  }
  @Watch("inlineData", { deep: true })
  async updateData() {
    if (!this.inlineData) return;
    this.panels = this.inlineData.map((x, i) => ({
      value: x,
      panelId: `p${i}`,
    }));
    this.data = this.panels.map((x, i) => x.value);
    await this.drawRect();
  }
  @Watch("width")
  async canvasResize() {
    this.overlay.width = this.width;
    canvasGrid.vueCanvas.canvas.width = this.width;

    this.frame =
      this.frame ||
      window.requestAnimationFrame(() => {
        this.updateMask();
        this.drawRect();
      });
  }

  @Watch("tileOptions", { deep: true })
  async resize() {
    this.data = this.panels.map((x, i) => x.value);

    this.frame =
      this.frame ||
      window.requestAnimationFrame(() => {
        this.updateMask();
        this.drawRect();
      });
  }
  @Watch("panelCenters")
  async updatePanelCenters() {
    const ctx = this.overlay.getContext("2d") as CanvasRenderingContext2D;
    this.clearScaleAndTranslateOverlayMap(ctx);
    this.wetmapOverlayWrapper.clearParticles();
  }
  private BuildGrid(rows: number, columns: number) {
    let width = 50;
    let height = 50;
    let border = this.tileOptions.border;
    let centers: Array<IPanelCenter> = [];
    for (let i = 0; i < rows; i++) {
      for (let c = 0; c < columns; c++) {
        let x = c * width + border * c + this.baseOffset + this.offsetX;
        let y = i * height + border * i + this.baseOffset + this.offsetY;
        centers.push({
          left: x + width / 2,
          top: y + height / 2,
          row: i,
          col: c,
          height,
          width,
          type: "rect",
          angle: 0,
        });
      }
    }
    return centers;
  }

  @Watch("wetmapflags", { deep: true })
  async flagUpdate() {
    await this.resize();
  }

  @Watch("offsetY")
  async changeOffsetY() {
    await this.resize();
  }
  @Watch("offsetX")
  async changeOffsetX() {
    await this.resize();
  }
  @Watch("board")
  async changeData() {
    await this.drawRect();
  }
  @Watch("radius")
  onRadiusChange(val: number) {}
  @Watch("blur")
  onBlurChange(val: number) {}

  mouseOut() {
    this.$emit("out");
  }

  async startRealtimeChannel() {
    hubs.subscribe("Result", async (message: any) => {
      if (this.wetmapflags.debug) console.log("result", message);
    });
    hubs.subscribe("Alert", async (message: any) => {
      console.log("alert", message);
    });

    hubs.subscribe("Notify", async (message: any) => {
      if (message.panels) {
        if (this.wetmapflags.debug) {
          console.log(message.date, message.panels.slice(0, 10));
        }
        this.panels = message.panels as PanelModel[];
        if (this.panels.length > 3000) {
          this.panels = this.panels.filter((x, i) => i % 2 == 0);
          if (this.wetmapflags.debug) {
            console.log(
              "too many values, skip half of them",
              this.panels.length,
            );
          }
        }
        this.data = this.panels.map((x, i) => x.value);

        await this.drawRect();
      } else {
        if (this.wetmapflags.debug) {
          console.log("panels are empty", message);
        }
      }
    });

    await hubs.startConnection();
  }

  private findIndexFromCoordinates(row: number, col: number) {
    return col + 46 * row;
  }
  get arrayOfGradient() {
    return this.getColors();
  }
  private getColors() {
    const f = Object.keys(this.gradientOptions);
    return f
      .map((x) => ({ val: this.gradientOptions[x], col: x }))
      .sort((a, b) => a.val - b.val);
  }

  private findColor(value: number, max: number) {
    const mapValue = (1 / max) * value;
    const cols = this.getColors();
    const color = cols.find((x) => x.val > mapValue);
    if (!color) return cols[cols.length - 1].col;
    return color.col;
  }

  private DrawPlan(centers: IPanelCenter[], ctx: CanvasRenderingContext2D) {
    centers.forEach((p) => {
      let width = p.width || this.tileOptions.width / 2;
      let height = p.height || this.tileOptions.height / 2;
      let border = this.tileOptions.border;
      canvasGrid.vueCanvas.globalAlpha = 1;
      let y = p.top + this.baseOffset + this.offsetY;
      let x = p.left + this.baseOffset + this.offsetX;
      let center = [x + width / 2, y + height / 2];
      if (p.type == "circle") {
        ctx.beginPath();
        ctx.arc(
          center[0],
          center[1],
          p.height || height,
          0,
          2 * Math.PI,
          false,
        );
        ctx.stroke();
        ctx.closePath();
      } else {
        if (p.angle) {
          const t = ctx.getTransform();
          ctx.translate(x, y);
          ctx.rotate((p.angle * Math.PI) / 180);
          ctx.translate(-x, -y);
          ctx.strokeRect(x, y, width, height);
          ctx.setTransform(t);
        } else {
          ctx.strokeRect(x, y, width, height);
        }
      }
      if (this.data) {
        const index = this.findIndexFromCoordinates(p.row, p.col);
        if (this.data[index]) {
          if (this.wetmapflags.showPanelColors) {
            canvasGrid.vueCanvas.globalAlpha = 0.3;

            const v = this.data[index];
            var color = this.findColor(v, this.max);
            if (color) {
              const ap = ctx.globalAlpha;
              ctx.globalAlpha = 0.4;
              ctx.fillStyle = color;
              if (p.type == "circle") {
                ctx.beginPath();
                ctx.arc(
                  center[0],
                  center[1],
                  (p.height || height) - 2,
                  0,
                  2 * Math.PI,
                  false,
                );
                ctx.fill();
                ctx.closePath();
              } else {
                if (p.angle) {
                  const t = ctx.getTransform();

                  ctx.translate(x, y);
                  ctx.rotate((p.angle * Math.PI) / 180);
                  ctx.translate(-x, -y);
                  ctx.fillRect(x + 2, y + 2, width - 4, height - 4);
                  ctx.setTransform(t);
                } else {
                  ctx.fillRect(x + 2, y + 2, width - 4, height - 4);
                }
              }

              ctx.globalAlpha = ap;
            }
          }
        }
      }
    });
  }

  async created() {
    canvasGrid = new CanvasGrid();
  }
  stop = false;
  wetmapOverlayWrapper: WetmapOverlay;
  animate() {
    if (!this.stop)
      requestAnimationFrame(() => {
        this.animate();
        this.draw();
      });
    this.wetmapOverlayWrapper.showHeatmap = this.wetmapflags.showHeatmap;
    this.wetmapOverlayWrapper.showValues = this.wetmapflags.showValues;
    this.wetmapOverlayWrapper.setColors(this.getColors());
    const ctx = this.overlay.getContext("2d") as CanvasRenderingContext2D;
    this.clearScaleAndTranslateOverlayMap(ctx);
    if (this.wetmapOverlayWrapper.colors.length) {
      if (this.panelCenters && this.panelCenters.length > 0) {
        this.wetmapOverlayWrapper.update(this.panelCenters, this.data);
      } else {
        this.wetmapOverlayWrapper.update(this.debugCenters, this.data);
      }
      this.wetmapOverlayWrapper.draw(ctx);
    }
  }

  private clearScaleAndTranslateOverlayMap(ctx: CanvasRenderingContext2D) {
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(this.zoom, this.zoom);
    ctx.translate(this.cameraOffset.x, this.cameraOffset.y);

    ctx.clearRect(
      0 - this.cameraOffset.x,
      0 - this.cameraOffset.y,
      this.overlay.width * (1 / this.zoom),
      this.overlay.height * (1 / this.zoom),
    );
  }

  panelHoverHandler(evt: Event) {
    const p = (evt as CustomEvent).detail as Particle;
    if (p == null) {
      this.$emit("out");
    } else {
      this.$emit("hover", {
        x: p.x,
        y: p.y,
        location: `Row ${p.row} Col ${p.col}`,
        value: p.value,
      });
    }
  }
  mouseMoveHandler(evt: MouseEvent) {
    this.wetmapOverlayWrapper.mouseMoveHandler(
      evt,
      this.zoom,
      this.cameraOffset.x,
      this.cameraOffset.y,
    );
  }
  isDragging = false;
  dragStart = { x: 0, y: 0 };
  cameraOffset = { x: 0, y: 0 };
  mouseDown(e: Event) {
    this.isDragging = true;
    this.stop = false;
    requestAnimationFrame(this.animate);
    this.dragStart.x =
      this.getEventLocation(e).x / this.zoom - this.cameraOffset.x;
    this.dragStart.y =
      this.getEventLocation(e).y / this.zoom - this.cameraOffset.y;
  }
  mouseMove(e: Event) {
    if (this.isDragging) {
      this.cameraOffset.x =
        this.getEventLocation(e).x / this.zoom - this.dragStart.x;
      this.cameraOffset.y =
        this.getEventLocation(e).y / this.zoom - this.dragStart.y;
    }
  }
  mouseUp() {
    this.isDragging = false;
    this.stop = true;
  }

  getEventLocation(e: Event) {
    const t = e as TouchEvent;
    const m = e as MouseEvent;
    if (t.touches && t.touches.length == 1) {
      return { x: t.touches[0].clientX, y: t.touches[0].clientY };
    } else if (m.clientX && m.clientY) {
      return { x: m.clientX, y: m.clientY };
    }
    return { x: 0, y: 0 };
  }
  async mounted() {
    this.debugCenters = this.BuildGrid(10, 10); // build a debug version of the centers to avoid drawing empty maps
    this.stop = false;
    this.wetmapOverlayWrapper = new WetmapOverlay(
      this.max,
      this.arrayOfGradient,
    );

    this.wetmapOverlayWrapper.baseOffset = this.baseOffset;
    this.wetmapOverlayWrapper.offsetY = this.offsetY;
    this.wetmapOverlayWrapper.offsetX = this.offsetX;

    this.overlay = document.getElementById(
      "wetmapoverlay",
    ) as HTMLCanvasElement;
    this.overlay.addEventListener("mousemove", this.mouseMoveHandler);

    this.overlay.addEventListener("panelhover", this.panelHoverHandler);

    this.element = document.getElementById("heatmap") as HTMLCanvasElement;
    this.element.width = this.width;
    this.element.height = this.height;
    this.overlay.width = this.width;
    this.overlay.height = this.height;
    this.cameraOffset.x = 0;
    this.cameraOffset.y = 0;
    this.overlay.addEventListener("mousedown", this.mouseDown);
    this.overlay.addEventListener("mouseup", this.mouseUp);
    this.overlay.addEventListener("mousemove", this.mouseMove);
    canvasGrid.vueCanvas = this.element.getContext(
      "2d",
    ) as CanvasRenderingContext2D;

    if (this.background) {
      await this.backgroundUpdate();
    }
    var result = await this.preloadSprites();

    Promise.all(result).then(() => {
      this.updateMask();
      this.drawRect();
    });
    if (this.realtimeEnabled) {
      await this.startRealtimeChannel();
    }

    this.$nextTick(() => this.$emit("rendered"));
    this.animate();
  }
  private calculateX(cols: number) {
    return (
      cols * this.tileOptions.width +
      cols * this.tileOptions.border +
      this.baseOffset
    );
  }
  private calculateY(rows: number) {
    return (
      rows * this.tileOptions.height +
      rows * this.tileOptions.border +
      this.baseOffset
    );
  }
  paintImage(
    destination: CanvasRenderingContext2D,
    def: ImageDefinitionForCanvas,
  ) {
    const x = this.calculateX(def.options.col);
    const y = this.calculateY(def.options.row);

    const w =
      this.calculateX(def.options.toCol + 1) - x - this.tileOptions.border + 2;
    const h =
      this.calculateY(def.options.toRow + 1) - y - this.tileOptions.border + 2;
    destination.lineWidth = 2;
    destination.globalAlpha = 1;
    destination.globalCompositeOperation = "destination-out";
    destination.drawImage(def.image, x, y, w, h);
  }

  async preloadSprite(
    def: ImageDefinitionForCanvas,
  ): Promise<ImageDefinitionForCanvas> {
    return new Promise((resolve, reject) => {
      def.image.onload = () => {
        return resolve(def);
      };
      def.image.onerror = reject;
      def.image.src = def.src;
    });
  }
  updateMask() {
    canvasGrid.maskCanvas.canvas.width = 1000;
    canvasGrid.maskCanvas.canvas.height = 1000;
    canvasGrid.maskCanvas.fillStyle = this.maskColor;
    canvasGrid.maskCanvas.fillRect(
      0 + this.baseOffset,
      0 + this.baseOffset,
      1000,
      1000,
    );
    canvasGrid.masks.forEach((x) => this.paintImage(canvasGrid.maskCanvas, x));
  }
  async preloadSprites() {
    canvasGrid.maskCanvas = (
      document.createElement("canvas") as HTMLCanvasElement
    ).getContext("2d") as CanvasRenderingContext2D;
    if (this.masks == null) return [new Promise(() => {})];
    return this.masks.map((def) => {
      const imageD = {
        src: def.src,
        options: def.options,
        image: new Image(),
      };
      canvasGrid.masks.push(imageD);

      return this.preloadSprite(imageD);
    });
  }

  async drawRect() {
    this.stop = true;
    this.animate();
    this.draw();
  }

  draw() {
    // set scale factor from neutral transform
    canvasGrid.vueCanvas.setTransform(1, 0, 0, 1, 0, 0);
    canvasGrid.vueCanvas.scale(this.zoom, this.zoom);
    canvasGrid.vueCanvas.translate(this.cameraOffset.x, this.cameraOffset.y);
    canvasGrid.vueCanvas.globalAlpha = 1;
    canvasGrid.clearCanvas(this.zoom, this.cameraOffset);

    if (this.background && this.backgroundImage.image) {
      canvasGrid.vueCanvas.globalAlpha = 1;
      canvasGrid.vueCanvas.drawImage(
        this.backgroundImage.image,
        0 + this.offsetX + this.baseOffset,
        0 + this.offsetY + this.baseOffset,
        this.backgroundOptions.width,
        this.backgroundOptions.height,
      );
    }

    if (this.panelCenters && this.panelCenters.length > 0) {
      this.DrawPlan(this.panelCenters, canvasGrid.vueCanvas);
    } else {
      this.DrawPlan(this.debugCenters, canvasGrid.vueCanvas);
    }
    this.frame = null;
  }
  async destroyed() {
    this.stop = true;
    this.overlay.removeEventListener("mousemove", this.mouseMoveHandler);
    this.overlay.removeEventListener("panelhover", this.panelHoverHandler);

    this.overlay.removeEventListener("mousedown", this.mouseDown);
    this.overlay.removeEventListener("mouseup", this.mouseUp);
    this.overlay.removeEventListener("mousemove", this.mouseMove);

    hubs.unRegister("Result");
    hubs.unRegister("Alert");
    hubs.unRegister("Devices");
    hubs.unRegister("Notify");
    await hubs.stopConnection();
  }
}

export class TimelinePoint {
  timeId: number;
  values: Array<PanelModel>;
}

export class PanelModel {
  value: number;
  panelId: string;
}
