











































































import {
  AlarmRowPagedCollectionModel,
  PagedCollectionFilter,
} from "@/core/webapi";
import { AlarmService } from "@/services";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import * as icons from "@mdi/js";

@Component({
  components: {},
})
export default class AlarmList extends Vue {
  loading = false;
  model = new AlarmRowPagedCollectionModel();
  icons = {
    iconEdit: icons.mdiFileEdit,
  };
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortOperator: "ascending",
    includeDeleted: true,
    filters: [],
  };
  async created() {}
  open() {}
  async mounted() {
    await this.loadAlarms();
  }
  async loadAlarms() {
    this.loading = true;
    this.model = await AlarmService.getAlarms({
      ...this.filter,
      page: Number(this.filter.page) - 1,
    });
    this.loading = false;
  }
  async next() {
    await this.loadAlarms();
  }
  async prev() {
    await this.loadAlarms();
  }
  async input(number: number) {
    this.filter.page = number;
    await this.loadAlarms();
  }
}
