














































































































// eslint-disable-next-line object-curly-newline
import * as icons from "@mdi/js";
import { Component, Vue } from "vue-property-decorator";

import { AuthService } from "@/services";

@Component
export default class LoginDemo extends Vue {
  isPasswordVisible: boolean = false;
  email: string = "";
  loading = false;
  password: string = "";
  message: string = "";
  icons = icons;

  mounted() {}
  async login() {
    this.message = "";
    this.loading = true;

    const res = await AuthService.login({
      username: this.email,
      password: this.password,
    });
    if (res && res.token) {
      AuthService.setToken(res.token);
      this.$router.push("/realtime");
    } else {
      this.message = "Login failed. Check your credentials.";
    }

    this.loading = false;
  }
}
