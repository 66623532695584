var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-view-grid-plus-outline")]),_vm._v(" Add Nodes ")],1)],1)]}}]),model:{value:(_vm.addNodesDialog),callback:function ($$v) {_vm.addNodesDialog=$$v},expression:"addNodesDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Add Nodes to plan ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"from Row","type":"number"},model:{value:(_vm.startRow),callback:function ($$v) {_vm.startRow=$$v},expression:"startRow"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Column","type":"number"},model:{value:(_vm.startColumn),callback:function ($$v) {_vm.startColumn=$$v},expression:"startColumn"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"to Row","type":"number"},model:{value:(_vm.endRow),callback:function ($$v) {_vm.endRow=$$v},expression:"endRow"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Column","type":"number"},model:{value:(_vm.endColumn),callback:function ($$v) {_vm.endColumn=$$v},expression:"endColumn"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Width","type":"number"},model:{value:(_vm.width),callback:function ($$v) {_vm.width=$$v},expression:"width"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Height","type":"number"},model:{value:(_vm.height),callback:function ($$v) {_vm.height=$$v},expression:"height"}})],1)],1),_c('v-text-field',{attrs:{"label":"Border","type":"number"},model:{value:(_vm.border),callback:function ($$v) {_vm.border=$$v},expression:"border"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addNodes(
            _vm.startRow,
            _vm.startColumn,
            _vm.endRow,
            _vm.endColumn,
            _vm.width,
            _vm.height,
            _vm.border
          )}}},[_vm._v(" Add ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.addNodesDialog = false}}},[_vm._v(" Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }