
































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DemoForm extends Vue {
  @Prop({ default: {} }) informationData: any;
  optionsLocal: any = {};
  mounted() {
    this.optionsLocal = JSON.parse(JSON.stringify(this.informationData));
  }
  resetForm = () => {
    this.optionsLocal = JSON.parse(JSON.stringify(this.informationData));
  };
}
