
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { hubs } from "../services/hubs/hub.service";

import {} from "@/services";
export class OnlineDeviceModel {
  deviceKey: string;
  subscribed: boolean;
  groupId: number;
  deviceName: string;
  lastSeen: string;
  lastPacket: any;
}
@Component
export default class OnlineDevices extends Vue {
  @Prop({ default: false }) showImage: boolean;
  @Prop({ default: "https://via.placeholder.com/150" }) image: string;
  isHubRunning = false;
  polling: any = null;
  devices: OnlineDeviceModel[] = [];
  selectedDevice: any = {};
  async fillDevices(message: any[]) {
    this.devices = message.map((x) => ({
      deviceKey: `${x.deviceName}-${x.groupId}`,
      subscribed: false,
      deviceName: x.deviceId,
      groupId: x.groupId,
      lastSeen: x.lastSeen,
      lastPacket: x.lastPacket,
      displayName: x.deviceName,
    }));

    if (
      this.devices.length === 1 &&
      this.devices.filter((x) => x.subscribed).length == 0
    ) {
      await this.subscribeToDevice(this.devices[0]);
      this.devices[0].subscribed = true;
      this.selectedDevice = this.devices[0];
      this.$emit("select", this.selectedDevice);
    }
  }
  updateDevices(message: any[]) {
    this.devices = message.map((x) => {
      const d = this.devices.find(
        (y) => y.deviceName == x.deviceId && y.groupId == x.groupId,
      );
      if (d != null) {
        d.lastSeen = x.lastSeen;
        d.lastPacket = x.lastPacket;
        return d;
      }
      return {
        deviceKey: `${x.deviceName}-${x.groupId}`,
        subscribed: false,
        groupId: x.groupId,
        deviceName: x.deviceId,
        lastSeen: x.lastSeen,
        lastPacket: x.lastPacket,
        displayName: x.deviceName,
      };
    });
  }
  async mounted() {
    // if the system is already subscribed, I don't subscribe again
    if (this.isHubRunning) return;
    hubs.subscribe("Devices", async (message: any[]) => {
      this.isHubRunning = true;
      if (this.devices.length == 0) {
        await this.fillDevices(message);
      } else {
        // merge
        this.updateDevices(message);
      }
    });

    this.polling = setInterval(() => {
      this.refreshDevices();
    }, 15000);
  }
  async refreshDevices() {
    if (hubs.isConnected()) await hubs.send("GetDevices");
  }
  async updateDevice() {
    var subscribedDevices = this.devices.filter((x) => x.subscribed);
    for (var device of subscribedDevices) {
      await this.unsubscribeFromDevice(device);
      device.subscribed = false;
    }
    await this.subscribeToDevice(this.selectedDevice);
    this.selectedDevice.subscribed = true;
    this.$emit("select", this.selectedDevice);
  }
  async unsubscribeFromDevice(device: OnlineDeviceModel) {
    await hubs.sendMessage("UnsubscribeFromDevice", {
      DeviceId: `${device.deviceName}-${device.groupId}`,
    });
  }
  async subscribeToDevice(device: OnlineDeviceModel) {
    await hubs.sendMessage("SubscribeToDevice", {
      DeviceId: `${device.deviceName}-${device.groupId}`,
    });
  }

  destroyed() {
    if (this.polling != null) clearInterval(this.polling);
    this.devices
      .filter((x) => x.subscribed)
      .forEach(async (x) => {
        await this.unsubscribeFromDevice(x);
        x.subscribed = false;
      });
    hubs.unRegister("Devices");
    this.isHubRunning = false;
  }
}
