import { IPanelCenter } from "@/services/devices";
import Particle from "./Particle";

export default class WetmapOverlay {
    particles: Particle[] = [];
    colors: any[];
    max: number;
    baseOffset = 10;
    offsetY = 10;
    offsetX = 10;
    tick = 0;
    showHeatmap = true;
    showValues = true;
    constructor(max: number, colors: any[]) {
        this.colors = colors;
        this.max = max;
    }
    currentMousePosition = { x: 0, y: 0 };
    mouseMoveHandler(e: MouseEvent, zoomFactor: number, cameraOffsetX: number, cameraOffsetY: number) {
        const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
        const pos = {
            x: (e.clientX - rect.left) / zoomFactor + cameraOffsetX,
            y: (e.clientY - rect.top) / zoomFactor + cameraOffsetY,
        };
        this.currentMousePosition = pos;
        if (this.particles.length) {
            const p = this.particles.find((p) => p.isMouseIn(pos.x, pos.y));

            e.target?.dispatchEvent(new CustomEvent("panelhover", { detail: p }));
        }
    }
    setColors(colors: any[]) {
        if (colors.length != this.colors.length) {
            this.colors = colors;
        }
    }
    clearParticles() {
        this.particles = [];
    }
    addParticle(p: Particle) {
        this.particles.push(p);
    }
    private findIndexFromCoordinates(row: number, col: number) {
        return col + 46 * row;
    }
    update(centers: IPanelCenter[], data: Array<number>) {
        this.tick += 0.1;
        if (data.length == 0) {
            this.clearParticles();
        }
        for (const c of centers) {
            let p: Particle | undefined;
            if (this.particles.length) {
                p = this.particles.find((x) => x.row == c.row && x.col == c.col);
            }
            if (!p) {
                const y = c.top + this.baseOffset / 2 + this.offsetY;
                const x = c.left + this.baseOffset + this.offsetX;
                const center = [x + Number(c.width) / 2, y + Number(c.height) / 2];

                p = new Particle(
                    c.row,
                    c.col,
                    center[0],
                    center[1],
                    Math.min(Number(c.width), Number(c.height)) / 2,
                    "transparent",
                );
                p.width = Number(c.width);
                p.height = Number(c.height);
                this.addParticle(p);
            }
            const index = this.findIndexFromCoordinates(p.row, p.col);
            if (index != null && data.length) {
                const value = data[index];
                const mapValue = (1 / this.max) * value;
                const color = this.colors.find((x) => x.val > mapValue);

                p.color = color ? color.col : this.colors[this.colors.length - 1].col;
                p.value = value;
            }
            p.active = true;
        }

        if (this.particles.length) {
            this.particles = this.particles.filter((x) => x.active);
            for (const x of this.particles) {
                x.update(this.tick, this.currentMousePosition);
            }
        }
    }
    draw(ctx: CanvasRenderingContext2D) {
        this.particles
            .sort((a, b) => b.value - a.value)
            .forEach((x) => x.draw(ctx, this.showHeatmap, this.showValues));
    }
}