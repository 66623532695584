





















import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ControlsCpt extends Vue {
  @VModel() speed: string;

  @Prop({ default: false }) isPlaying: boolean;
  @Prop({ default: false }) disabled: boolean;
  next() {
    this.$emit("next");
  }
  stop() {
    this.$emit("stop");
  }
  play() {
    this.$emit("play");
  }
  prev() {
    this.$emit("prev");
  }
  async created() {}

  async mounted() {}
}
