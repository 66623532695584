






















































































import { AlarmDetailModel } from "@/core/webapi";
import { AlarmService } from "@/services";
import { Component, Vue } from "vue-property-decorator";
import * as icons from "@mdi/js";
@Component({
  components: {},
})
export default class AlarmDetail extends Vue {
  model: AlarmDetailModel = new AlarmDetailModel();

  icons = {
    start: icons.mdiCircleSlice1,
    panels: icons.mdiSolarPanel,
    end: icons.mdiCircleSlice7,
    different: icons.mdiTriangleOutline,
  };
  loading = false;
  get history() {
    return this.model.alarmHistoryRows;
  }
  async resolve(statusId: number) {
    this.loading = true;
    await AlarmService.resolveAlarm(this.model.alarmId as number, statusId);
    this.model = await AlarmService.getAlarm(this.model.alarmId as number);
    this.loading = false;
  }
  async created() {}

  async mounted() {
    this.loading = true;
    var id = Number(this.$route.params.id);
    this.model = await AlarmService.getAlarm(id);
    this.loading = false;
  }
}
