


import { Component, Prop, Vue } from "vue-property-decorator";
class HotkeyModel {
  key: string;
  ctrl: boolean = false;
  alt: boolean = false;
  handler: Function;
}
export class HotkeyInputModel {
  hotkey: string;
  handler: Function;
}

@Component({})
export default class HotkeysCpt extends Vue {
  @Prop({ default: () => new Array<string>() }) hotkeys: HotkeyInputModel[];

  configuredHotkeys: HotkeyModel[] = [];

  async created() {
    if (this.hotkeys.length > 0) {
      this.hotkeys
        .map((x) => ({
          input: x.hotkey.split("+").reverse(),
          handler: x.handler,
        }))
        .forEach((x) => {
          const model = new HotkeyModel();
          model.key = x.input[0];
          if (x.input.length > 1) {
            model.ctrl = x.input[1] == "CTRL";
            model.alt = x.input[1] == "ALT";
          }
          if (x.input.length > 2) {
            model.ctrl = model.ctrl || x.input[2] == "CTRL";
            model.alt = model.alt || x.input[2] == "ALT";
          }
          model.handler = x.handler;
          this.configuredHotkeys.push(model);
        });
    }
  }

  async mounted() {
    document.addEventListener("keydown", this.keydownHandler, false);
  }
  keydownHandler(event: KeyboardEvent) {
    const key = event.key;
    const ctrl = event.ctrlKey || event.metaKey;
    const alt = event.altKey;
    const result = this.configuredHotkeys.filter(
      (x) => x.key == key && x.ctrl == ctrl && x.alt == alt,
    );

    if (result && result.length > 0) {
      result[0].handler();
      event.preventDefault();
    }
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.keydownHandler);
  }
}
