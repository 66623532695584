




































































































































import AppVue from "@/AppVue.vue";
import { AlarmRowPagedCollectionModel } from "@/core/webapi";
import { AlarmService } from "@/services";
import * as icons from "@mdi/js";

import Component from "vue-class-component";

@Component
export default class AppBarUserMenu extends AppVue {
  icons = icons;
  email = "someone@somewhere.how";
  role = "User";
  name = "Username";
  alarms: AlarmRowPagedCollectionModel = {} as AlarmRowPagedCollectionModel;
  get activeAlarms() {
    var items = this.alarms.items;
    if (!items) return 0;
    return items?.filter((x) => !x.end).length;
  }
  async mounted() {
    this.email = this.currentEmailAddress;
    this.role = this.currentFirstRole;
    this.name = this.currentName;

    // load alarms
    this.alarms = await AlarmService.getMyAlarms();
  }
}
