import { AppModule, AppRouteConfig } from "@/core/appTypes";
import DemoViewer from './DemoViewer.vue';
import * as icons from "@mdi/js";
export const demoModule: AppModule = {
    routes: [
        {
            path: '/Demo',
            name: 'Demo',
            component: DemoViewer,
            visible: true,
            icon: icons.mdiTable,
            meta: {
                layout: 'blank',
            },
            label: 'Demo',
        },
    ]
};

