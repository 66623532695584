import { AppModule, AppRouteConfig } from "@/core/appTypes";
import AlarmList from "./AlarmList.vue";
import AlarmDetail from "./AlarmDetail.vue";


import * as icons from "@mdi/js";
export const alarmsModule: AppModule = {
    routes: [
        {
            path: '/admin/Alarms',
            name: 'Alarms',
            component: AlarmList,
            meta: {
                allowAnonymous: false,
            },
            visible: true,
            icon: icons.mdiAlertOctagon,
            label: 'Alarms',
            order: 30
        },
        {
            path: '/admin/alarms/:id',
            name: 'AlarmDetail',
            component: AlarmDetail,
            meta: {
                allowAnonymous: false,
            },
            visible: false,
            icon: icons.mdiAlertOctagon,
            label: 'Alarms',
        }
    ]
};