

































import { Component, Prop, Vue } from "vue-property-decorator";
class NavLinkModel {
  public Url: string;
  public Label: string;
}
import { routes } from "@/router";
import * as icons from "@mdi/js";
import NavMenuLinkVue from "../vertical-nav-menu/components/NavMenuLink.vue";

@Component({
  components: {
    NavMenuLink: () => Promise.resolve(NavMenuLinkVue),
  },
})
export default class LeftMenu extends Vue {
  @Prop({ default: false }) isDrawerOpen: boolean;
  icons = icons;
  links: NavLinkModel[] = routes
    .filter((x) => x.visible)
    .sort((a, b) => Number(a.order) - Number(b.order))
    .map((x) => ({
      Url: x.path,
      Name: x.name as string,
      Label: x.label as string,
      Icon: x.icon,
    }));
  async created() {}

  async mounted() {}
}
