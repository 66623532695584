























































































































import { AggregationSegment, DeviceTableRowModel } from "@/core/webapi";
import { dataService } from "@/services";
import { DeviceService } from "@/services/devices";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AlertSearch extends Vue {
  loading: boolean = false;
  devices: any[] = ["10"];
  form: any = {
    fullDeviceId: "",
    start: "",
    end: "",
    colStart: 0,
    colEnd: 10,
    rowStart: 0,
    rowEnd: 10,
  };
  model: Array<DeviceTableRowModel> = [];
  parsedModel: Array<any> = [];
  get isFormValid() {
    return (
      this.form.fullDeviceId &&
      this.form.start &&
      this.form.end &&
      this.form.start < this.form.end
    );
  }
  sum(a: any, b: any) {
    return Number(a) + Number(b);
  }
  async search() {
    this.loading = true;
    const deviceIdArray = this.form.fullDeviceId.split("-");
    this.model = await dataService.queryAlerts(
      new AggregationSegment({
        deviceId: deviceIdArray[0],
        groupId: deviceIdArray[1],
        segmentStart: this.form.start,
        segmentEnd: this.form.end,
      }),
    );
    this.parsedModel = dataService.parseResults(this.model);
    this.loading = false;
  }

  async created() {
    this.form.start =
      this.$route.query.start || moment.utc().add(-15, "minutes").toDate();
    this.form.end = this.$route.query.end || moment.utc().toDate();
    this.form.deviceId = this.$route.query.deviceId || "10";
    const results = await DeviceService.GetSimpleList({
      page: 0,
      pageSize: 10,
      search: "",
      sortBy: "",
      sortOperator: "ascending",
      includeDeleted: false,
      filters: [],
    });

    this.devices =
      results.items?.map((x) => ({
        value: `${x.hardwareId}-${x.groupId}`,
        text: x.name,
        deviceId: x.hardwareId,
        groupId: x.groupId,
      })) || [];
  }

  async mounted() {}

  save() {
    this.loading = true;
    const contentArray: string[] = [];
    const header: string[] = ["PartitionKey", "RowKey", "Timestamp"];
    for (var i = 0; i < 46 * 46; i++) {
      header.push(`Node${i}`);
    }
    contentArray.push(header.join(","));
    this.model.forEach((r) => {
      const output = [];
      output.push(r.partitionKey);
      output.push(r.rowKey);
      output.push(r.timestamp);
      (JSON.parse(r.pretriggerData as string) as number[]).forEach((d) =>
        output.push(d),
      );
      contentArray.push(output.join(","));
    });

    const csvContent = "data:text/csv;charset=utf-8," + contentArray.join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "my_data.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
    this.loading = false;
  }
}
