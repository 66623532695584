











































































































































































import { Component, Vue } from "vue-property-decorator";

import { AggregateResultModel, dataService } from "@/services";

import moment from "moment";
import SquaredMap from "@/components/SquaredMap.vue";
import { AggregationSegment } from "@/core/webapi";

class AggregateForm {
  dataType: string;
  fullDeviceId: string;
  start: string | Date;
  end: string | Date;
  colStart: number;
  colEnd: number;
  rowStart: number;
  rowEnd: number;
}
// eslint-disable-next-line object-curly-newline
import { mdiAlarm, mdiCalendar } from "@mdi/js";
import ControlsCpt from "@/components/player/controls.vue";
import HotkeysCpt, { HotkeyInputModel } from "@/components/hotkeys/hotkeys.vue";
import {
  DeviceService,
  IPanelCenter,
  MapSettings,
  TileOptions,
} from "@/services/devices";
import { WetMapFlags } from "@/components/RendererModels";
@Component({
  components: {
    SquaredMap: () => Promise.resolve(SquaredMap),
    ControlsCpt: () => Promise.resolve(ControlsCpt),
    HotkeysCpt: () => Promise.resolve(HotkeysCpt),
  },
})
export default class ArchivedGridViewer extends Vue {
  icons = {
    mdiAlarm,
    mdiCalendar,
  };
  backgroundPhoto = "";
  loading = false;
  speed = 0;
  isPlaying = false;
  timeProps = { format: "24hr" };
  devices: any[] = [];
  zoom: number = 1;
  form: AggregateForm = {
    dataType: "PT5MAVG",
    fullDeviceId: "",
    start: "",
    end: "",
    colStart: 0,
    colEnd: 10,
    rowStart: 0,
    rowEnd: 10,
  };
  mapConfig: any = {
    blur: 2,
    radius: 5,
    board: "10",
    tileOptions: {
      width: 135,
      height: 68,
      border: 4,
    },
    offsetX: 0,
    offsetY: 0,
    gradientOptions: {},
    max: 138,
    wetmapflags: {
      options: "by adding a string, I wonder what happens",
      wi: 20,
      debug: false,
      showMask: false,
      showHeatmap: false,
      showHeaders: true,
      showValues: true,
      showPanelColors: true,
    },
    masks: [],
  };
  model: AggregateResultModel[] = [];
  selectedIndex = 0;
  hoverValue: number = 0;
  hoverLocation: string = "";
  autoPlay = 0;
  shareUrl = "";
  availableWidth = 1000;
  centers: IPanelCenter[] = [];
  dataTypes = [
    { text: "Average 5 min", value: "PT5MAVG" },
    { text: "Max 5 min", value: "PT5MMAX" },
    { text: "Min 5 min", value: "PT5MMIN" },
  ];
  hotkeys: HotkeyInputModel[] = [
    {
      hotkey: "ArrowLeft",
      handler: () => {
        this.prev(1, false);
      },
    },
    {
      hotkey: "ArrowRight",
      handler: () => {
        this.next(1, false);
      },
    },
    {
      hotkey: "CTRL+ArrowLeft",
      handler: () => {
        this.selectedIndex -= 9;
        this.prev(10, false);
      },
    },
    {
      hotkey: "CTRL+ArrowRight",
      handler: () => {
        this.next(10, false);
      },
    },
    {
      hotkey: " ",
      handler: () => {
        this.play();
      },
    },
  ];
  get currentFrame() {
    if (this.model && this.model.length > 0)
      return this.model[this.selectedIndex];
    return { data: [] };
  }
  labels = ["test", "test2"];
  get ticksLabels() {
    if (this.model && this.model.length > 0) {
      const numberOfLabels = this.model.length;
      let step = 1;
      if (numberOfLabels > 500) {
        step = 100;
      } else if (numberOfLabels > 300) {
        step = 50;
      } else if (numberOfLabels > 100) {
        step = 20;
      } else if (numberOfLabels > 20) {
        step = 2;
      }
      const format = "HH:mm";
      let day = -1;
      let skip = 0;
      return this.model.map((x, i) => {
        if (i % step == 0) {
          if (x.timestamp.day() != day) {
            day = x.timestamp.day();
            skip = this.model.length > 10 ? 1 : 0;
            return x.timestamp.local().format("Do MMM HH:mm");
          }
          if (skip > 0) {
            skip--;
            return "";
          }
          return x.timestamp.local().format(format);
        } else {
          return "";
        }
      });
    }
    return [];
  }
  get currentSpeed() {
    const v = 1000;
    return v / (this.speed + 1);
  }
  tick() {
    if (this.isPlaying) {
      if (this.selectedIndex == this.model.length - 1) {
        this.stop();
      } else {
        this.next();
      }
      setTimeout(this.tick, this.currentSpeed);
    }
  }
  async share() {
    this.shareUrl =
      window.location.href.split("?")[0] +
      `?start=${moment(this.form.start).toISOString()}&end=${moment(
        this.form.end,
      ).toISOString()}`;
  }
  async play() {
    if (this.isPlaying) {
      this.stop();
      return;
    }
    this.isPlaying = true;
    if (this.selectedIndex == this.model.length - 1) {
      this.selectedIndex = 0;
    }
    this.tick();
  }
  async stop() {
    this.isPlaying = false;
  }
  panelHover(item: any) {
    if (item && item.value > -1) {
      this.hoverValue = item.value;
      this.hoverLocation = item.location;
    }
  }
  panelOut() {
    this.hoverLocation = "";
    this.hoverValue = 0;
  }
  selectDevice() {
    const selectedDevice = this.devices.find(
      (x) => x.value == this.form.fullDeviceId,
    );

    if (selectedDevice) {
      this.centers = selectedDevice.centers;
      this.backgroundPhoto = selectedDevice.photo;
      if (!selectedDevice.settings) {
        selectedDevice.settings = {
          backgroundOptions: {},
          tileOptions: null,
          wetmapflags: null,
          gradientModel: [],
        };
      }
      this.mapConfig.backgroundOptions =
        selectedDevice.settings.backgroundOptions;
      this.mapConfig.tileOptions =
        selectedDevice.settings.tileOptions || new TileOptions();
      this.mapConfig.offsetX = selectedDevice.settings.offsetX;
      this.mapConfig.offsetY = selectedDevice.settings.offsetY;
      this.mapConfig.wetmapflags =
        selectedDevice.settings.wetmapflags || new WetMapFlags();
      selectedDevice.settings.gradientModel.forEach((c: any) => {
        this.mapConfig.gradientOptions[c.color] = this.transform(c.value);
      });
    }
  }
  async loadData() {
    if (!this.form.fullDeviceId) return;
    this.loading = true;
    const deviceIdArray = this.form.fullDeviceId.split("-");

    this.selectDevice();

    this.model = await dataService.queryAggregateAlertsArray(
      new AggregationSegment({
        deviceId: deviceIdArray[0],
        groupId: deviceIdArray[1],
        segmentStart: this.form.start as string,
        segmentEnd: this.form.end as string,
        prefix: this.form.dataType,
      }),
    );
    this.selectedIndex = 0;
    this.loading = false;
  }

  getDateFromQuery(q: string) {
    if (q) return moment(q).toDate();
    else return "";
  }
  async created() {
    this.loading = true;
    this.form.start =
      this.getDateFromQuery(this.$route.query.start as string) ||
      moment.utc().add(-15, "minutes").toDate();
    this.form.end =
      this.getDateFromQuery(this.$route.query.end as string) ||
      moment.utc().toDate();
    this.form.fullDeviceId = (this.$route.query.deviceId ||
      "10-1000") as string;

    this.mapConfig.gradientOptions = {};
    const results = await DeviceService.GetList({
      page: 0,
      pageSize: 25,
      search: "",
      sortBy: "DeviceId",
      sortOperator: "descending",
      includeDeleted: false,
      filters: [],
    });

    this.devices =
      results.items?.map((x) => ({
        value: `${x.hardwareId}-${x.groupId}`,
        text: x.name,
        photo: x.photo,
        deviceId: x.hardwareId,
        groupId: x.groupId,
        centers: x.centers ? (JSON.parse(x.centers) as IPanelCenter[]) : [],
        settings: x.mapSettings
          ? (JSON.parse(x.mapSettings || "{}") as MapSettings)
          : null,
      })) || [];

    this.selectDevice();
    this.loading = false;
  }
  next(amount: number = 1, loop: boolean = true) {
    if (this.selectedIndex < this.model.length - amount)
      this.selectedIndex += amount;
    else this.selectedIndex = loop ? 0 : this.model.length - 1;
  }
  prev(amount: number = 1, loop: boolean = true) {
    if (this.selectedIndex - amount > 0) this.selectedIndex -= amount;
    else this.selectedIndex = loop ? this.model.length - 1 : 0;
  }
  getDimensions() {
    this.availableWidth =
      document.querySelector<HTMLElement>(".mapcontainer")?.offsetWidth || 1000;
  }
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  }
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  }
  transform(value: number) {
    return (1 / this.mapConfig.max) * value;
  }
}
