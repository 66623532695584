
import { AccountApiFactory, AdminLookupList, AuthApiFactory, Configuration, PagedCollectionFilter, UserRow } from "@/core/webapi";
import Vue from "vue";
import { AxiosFactory } from "../core";
const api = AccountApiFactory(new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create());


export const AccountService = new Vue({
    data: {
        lookups: {} as AdminLookupList
    },
    methods: {
        async getUsers(model: PagedCollectionFilter) {
            return await (await api.apiAdminAccountsListPost(model)).data;
        },
        async getUserDetail(id: number) {
            return await (await api.apiAdminAccountsIdGet(id)).data;
        },
        async getLookups() {
            if (!this.lookups.roles) {
                this.lookups = await (await api.apiAdminAccountsLookupsGet()).data;
            }
            return this.lookups;
        }

    }
}); 