
























































































import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js";
// import VerticalNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
// import ThemeSwitcher from "@/layouts/components/ThemeSwitcher.vue";
// import AppBarUserMenu from "@/layouts/components/AppBarUserMenu.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    // VerticalNavMenu,
    // ThemeSwitcher,
    // AppBarUserMenu,
  },
})
export default class DemoForm extends Vue {
  @Prop({ default: false }) isDrawerOpen: boolean;

  icons: any = {
    mdiMagnify,
    mdiBellOutline,
    mdiGithub,
  };
}
