























































































import { Component, Vue } from "vue-property-decorator";

import { AuthService } from "@/services";

@Component
export default class ResetPassword extends Vue {
  email: string = "";
  success = false;
  loading = false;
  async reset() {
    this.loading = true;
    await await AuthService.resetPassword({
      email: this.email,
    });
    this.loading = false;
    this.success = true;
  }
}
