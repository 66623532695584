import { AppConfig } from "@/configuration";
import {
    HubConnectionBuilder,
    LogLevel,
    HttpTransportType,
    HubConnection,
    HubConnectionState,
} from "@microsoft/signalr";
class DataHubs {
    connection: HubConnection;

    /**
     *
     */
    constructor() {
        this.connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Information)
            .withUrl(`${AppConfig.WebsocketUrl}/realtime`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();


    }

    public isConnected() {
        return this.connection.state == HubConnectionState.Connected;
    }
    public getConnectionState() {
        return this.connection.state;
    }
    public async stopConnection() {
        await this.connection.stop();
    }

    public unRegister(event: string) {
        this.connection.off(event);
    }

    public async startConnection() {
        await this.connection.start();
        await this.send("GetDevices");
    }

    public subscribe(event: string, callback: (...args: any[]) => void) {
        return this.connection.on(event, callback);
    }


    public async send(event: string) {
        return await this.connection.send(event);
    }
    public async sendMessage(event: string, ob: any) {
        return await this.connection.send(event, ob);
    }
}

export const hubs = new DataHubs();
