

































import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
} from "@mdi/js";

// demos
import AccountSettingsAccount from "./AccountSettingsAccount.vue";
import AccountSettingsSecurity from "./AccountSettingsSecurity.vue";
import AccountSettingsInfo from "./AccountSettingsInfo.vue";
import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
@Component({
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
  },
})
export default class AccountSettings extends AppVue {
  tab: string = "Account";

  // tabs
  tabs = [
    { title: "Account", icon: mdiAccountOutline },
    { title: "Security", icon: mdiLockOpenOutline },
    // { title: "Info", icon: mdiInformationOutline },
  ];
  icons = {
    mdiAccountOutline,
    mdiLockOpenOutline,
    mdiInformationOutline,
  };
  // account settings data
  accountSettingData = {
    account: {
      avatarImg: require("@/assets/images/avatars/1.png"),
      username: this.currentName,
      name: this.currentName,
      email: this.currentEmailAddress,
      role: this.currentFirstRole,
      status: "Active",
      company: "",
    },
    information: {
      bio: "",
      birthday: "",
      phone: "",
      website: "",
      country: "",
      languages: [],
      gender: "",
    },
  };
}
