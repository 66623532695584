


































































































































import { mdiAlertOutline, mdiCloudUploadOutline } from "@mdi/js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DemoForm extends Vue {
  @Prop({ default: {} }) accountData: any;
  icons = {
    mdiAlertOutline,
    mdiCloudUploadOutline,
  };
  accountDataLocale: any = {};
  resetForm = () => {
    this.accountDataLocale = JSON.parse(JSON.stringify(this.accountData));
  };
  mounted() {
    this.accountDataLocale = JSON.parse(JSON.stringify(this.accountData));
  }
}
