













































































































import { ActiveSelection } from "fabric/fabric-impl";
import { Component, VModel, Vue } from "vue-property-decorator";
import { SelectedEntity } from "./EditorInterfaces";

@Component({
  components: {},
})
export default class EntityDetailsEditor extends Vue {
  @VModel() entity: SelectedEntity;
  updateSelectedEntity() {
    this.$emit("update", this.entity);
  }
  get entityName() {
    if (this.entity.data && this.entity.data.panel) {
      return `Node Row ${this.entity.data.row} Col ${this.entity.data.col}`;
    }
    if (this.entity.object && this.entity.object.type == "group") {
      return this.entity.object.type;
    }
    if (this.entity.object && this.entity.object.type == "activeSelection") {
      return `Selection of ${this.selectionLength} objects`;
    }
    return this.entity.object.type;
  }

  get selectionLength() {
    return (this.entity.object as ActiveSelection)._objects.length;
  }
  async created() {}

  async mounted() {}
}
