





































































































import { Component, Vue } from "vue-property-decorator";

import { AuthService } from "@/services";

@Component
export default class ResetPasswordForm extends Vue {
  mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})",
  );
  disabled = false;
  displayMessage = false;
  loading = false;
  message = "";
  form = {
    email: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };
  async created() {
    const email = (this.$route.params.userEmailAddress ||
      this.$route.query.email) as string;
    const tokenP =
      this.$route.params.emailConfirmationToken || this.$route.query.token;

    const token = decodeURIComponent(tokenP as string);
    this.form = {
      email,
      newPassword: "",
      confirmPassword: "",
      token,
    };
  }
  async mounted() {
    if (!this.$route.params.emailConfirmationToken) {
      // error this link is not valid
      this.displayMessage = true;
      this.message = "Error. The link you have followed is not valid.";
      return;
    }
  }
  async confirmEmailAndSetPassword() {
    this.loading = true;
    this.disabled = true;
    this.displayMessage = false;
    try {
      const passwordValidation = this.mediumRegex.test(this.form.newPassword);
      const passwordMatch = this.form.newPassword === this.form.confirmPassword;
      if (!passwordValidation) {
        this.message = "The password is not strong enough";
        this.displayMessage = true;
        this.loading = false;
        this.disabled = false;
        return;
      }
      if (!passwordMatch) {
        this.message = "Passwords don't match";
        this.displayMessage = true;
        this.loading = false;
        this.disabled = false;
        return;
      }

      const response = await AuthService.restorePassword(this.form);
      if (response && response.token) {
        AuthService.setToken(response.token);
        //this.notify("Success", "The account has been updated.", "success");
        this.$router.push("/login").catch(() => {});
      }
    } catch (error: any) {
      this.displayMessage = true;
      this.message = "Error setting the password for this user.";
    } finally {
      this.loading = false;
      this.disabled = false;
    }
  }
}
