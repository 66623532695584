

































































































import { Component, Vue } from "vue-property-decorator";

import { AuthService } from "@/services";

@Component
export default class ConfirmEmail extends Vue {
  disabled = false;
  displayMessage = false;
  loading = false;
  message = "";
  confirming = true;
  mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})",
  );

  form = {
    email: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };
  async mounted() {
    if (!this.$route.params.emailConfirmationToken) {
      // error this link is not valid
      this.displayMessage = true;
      this.message = "Error. The link you have followed is not valid.";
      return;
    } else {
      this.loading = true;
      this.confirming = true;

      const emailAddress = decodeURIComponent(
        this.$route.params.userEmailAddress,
      );
      const token = decodeURIComponent(
        this.$route.params.emailConfirmationToken,
      );
      try {
        const response = await AuthService.confirmEmailAddress(
          token,
          emailAddress,
        );
        this.confirming = false;
        this.form.token = response.token?.token || "";
        this.form.email = response.email || "";
      } catch {
        // didn't work, hide the rest of the page.
        this.confirming = true;
      } finally {
        this.loading = false;
      }
    }
  }

  async setPassword() {
    const passwordValidation = this.mediumRegex.test(this.form.newPassword);
    const passwordMatch = this.form.newPassword === this.form.confirmPassword;
    if (!passwordValidation) {
      this.message = "The password is not strong enough";
      this.displayMessage = true;
      this.loading = false;
      this.disabled = false;
      return;
    }
    if (!passwordMatch) {
      this.message = "Passwords don't match";
      this.displayMessage = true;
      this.loading = false;
      this.disabled = false;
      return;
    }

    try {
      const response = await AuthService.restorePassword(this.form);
      if (response && response.token) {
        AuthService.setToken(response.token);
        //this.notify("Success", "The account has been updated.", "success");
        this.$router.push("/login").catch(() => {});
      }
    } catch {
      this.displayMessage = true;
      this.message = "Error setting the password for this user.";
    } finally {
      this.loading = false;
      this.disabled = true;
    }
  }
}
