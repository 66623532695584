import { AppModule, AppRouteConfig } from "@/core/appTypes";

import RealtimeGridWetmap from './RealtimeGridWetmap.vue';
import ArchivedGridViewer from './ArchivedGridViewer.vue';

import * as icons from "@mdi/js";
export const viewerModule: AppModule = {
    routes: [
        {
            path: '/realtime',
            name: 'realtime',
            component: RealtimeGridWetmap,
            visible: true,
            icon: icons.mdiTable,
            label: 'Realtime Viewer',
            order: 10
        },

        {
            path: '/gridArchive',
            name: 'GridArchive',
            component: ArchivedGridViewer,
            visible: true,
            icon: icons.mdiTable,
            label: 'Archive Map',
            order: 20
        },
    ]
};