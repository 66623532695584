
import axios, { AxiosRequestConfig } from "axios";

import { router } from "@/router";
import Vue from "vue";
import { AuthService } from "../auth/auth.service";



export const AxiosFactory = new Vue({
  methods: {
    createNoPragma() {
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {},
      };

      const axiosInstance = axios.create(config);
      return axiosInstance;
    },
    create() {
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {
          Pragma: "no-cache",
        },
      };

      const axiosInstance = axios.create(config);
      axiosInstance.interceptors.request.use(async (config) => {
        if (this.$auth && this.$auth.isAuthenticated()) {
          if (config && config.headers)
            config.headers['Authorization'] = `Bearer ${this.$auth.getToken()}`;
        }
        return config;
      }, async (error) => {
        return error;
      });

      axiosInstance.interceptors.response.use(
        response => response,
        async error => {
          // 422 is model validation error and we don't want any extra handling in that case
          if (error && error.response && error.response.status && error.response.status !== 422) {
            const errMessageObj: any = { title: "Error", message: "", type: "error" };
            switch (error.response.status) {
              case 401: {
                errMessageObj.message = "Not authenticated.";
                // we could refresh the token OR use a timed method to re-refresh the token automatically
                const response = await AuthService.refreshToken();
                if (response && response.token) {
                  AuthService.setToken(response.token);
                  console.log("Refreshing the token!");
                  return axiosInstance(error.config);
                }

                return Promise.reject(error);
                break;
              }
              case 403: {
                errMessageObj.message = "Insufficient permissions.";
                break;
              }
              case 500: {
                if (error.response
                  && error.response.data
                  && error.response.data.message) {

                  if (error.response.data.message.indexOf("The token is expired") > -1) {
                    // in case of expired refresh token, the error 500 is returned.
                    // we take it from here and go to the login page
                    if (router.currentRoute.path !== "/login") {
                      router
                        .push({ path: "/login", query: router.currentRoute.query })
                        .catch(() => { });
                    }
                    return error;

                  } else {
                    errMessageObj.message = error.response.data.message;
                  }
                } else {
                  errMessageObj.message = "An error occurred.";
                  // return and do not notify
                  return error;
                }
                break;
              }

              default: {
                errMessageObj.message = "An error occurred.";
                break;
              }
            }
          }

          return Promise.reject(error);
        },
      );

      return axiosInstance;
    },

  },
});
