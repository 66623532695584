import { AppModule, AppRouteConfig } from "@/core/appTypes";

import Editor from './Editor.vue';

import * as icons from "@mdi/js";
export const editorModule: AppModule = {
    routes: [
        {
            path: '/debug/editor',
            name: 'Editor',
            component: Editor,
            meta: {
                allowAnonymous: true,
            },
            visible: true,
            icon: icons.mdiBookEdit,
            label: 'Project Setup',
            order: 40
        },
    ]
};