import { WetMapFlags } from "@/components/RendererModels";
import { Configuration, DeviceApiFactory, DeviceLookupList, DeviceModelPagedCollectionModel, DeviceWithNodesModel, PagedCollectionFilter } from "@/core/webapi";
import Vue from "vue";
import { AxiosFactory } from "../core";

const api = DeviceApiFactory(new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create());

export interface IPanelCenter {
    row: number;
    col: number;
    top: number;
    left: number;
    height?: number;
    width?: number;
    type?: string;
    angle?: number;
}
export class ColorSetting {
    color: string;
    value: number;
}
export class TileOptions {
    /**
     *
     */
    constructor() {
        this.width = 100;
        this.height = 100;
        this.border = 2;
    }
    width: number;
    height: number;
    border: number;
}
export class MapSettings {
    /**
     *
     */
    constructor() {
        this.max = 250;
        this.tileOptions = new TileOptions();
        this.gradientModel = [
            { color: "White", value: 10 },
            { color: "Yellow", value: 50 },
            { color: "Red", value: 200 },
        ];
        this.offsetX = 0;
        this.offsetY = 0;
        this.backgroundOptions = {};
        this.wetmapflags = new WetMapFlags();
    }
    wetmapflags: WetMapFlags;
    offsetX: number;
    offsetY: number;
    tileOptions: TileOptions;
    gradientModel: ColorSetting[];
    max: number;
    backgroundOptions: any;
}


export const DeviceService = new Vue({
    data: {
        lookups: {} as DeviceLookupList
    },
    methods: {
        async GetList(model: PagedCollectionFilter) {
            return await (await api.apiAdminDevicesListPost(model)).data;
        },
        async GetSimpleList(model: PagedCollectionFilter) {
            return await (await api.apiAdminDevicesSimplelistPost(model)).data;
        },
        async GetDetail(deviceId: number) {
            return await (await api.apiAdminDevicesDeviceIdGet(deviceId)).data;
        },
        async GetDetailFromHardwareId(hardwareId: string, groupId: string) {
            return await (await api.apiAdminDevicesHardwareIdHwidGroupIdGet(hardwareId, groupId)).data;
        },
        async Update(model: DeviceWithNodesModel) {

            const defaults = { description: "" };
            return await (await api.apiAdminDevicesPost({ ...defaults, ...model }));
        },
        async getLookups() {
            if (!this.lookups.statusList) {
                this.lookups = await (await api.apiAdminDevicesLookupsGet()).data;
            }
            return this.lookups;
        }
    }
});