
// to detach vue listeners to the canvas, move it to an external service
export default class Particle {
    row: number;
    col: number;
    x: number;
    y: number;
    size: number;
    height: number = 1;
    width: number = 2;
    color: string;
    active: boolean = false;
    value: number = 0;
    hover: boolean = false;

    constructor(
        row: number,
        col: number,
        x: number,
        y: number,
        size: number,
        color: string,
    ) {
        this.row = row;
        this.col = col;
        this.x = x;
        this.y = y;
        this.size = size;
        this.color = color;
    }
    isMouseIn(x: number, y: number) {
        return x > this.x - this.width / 2 && x < this.x + this.width / 2
            &&
            y > this.y - this.height / 2 && y < this.y + this.height / 2;
    }
    update(tick: number, mousePosition: any) {
        if (mousePosition && this.isMouseIn(mousePosition.x, mousePosition.y)) {
            this.hover = true;
        } else {
            this.hover = false;
        }
    }
    draw(
        ctx: CanvasRenderingContext2D,
        showHeatmap: boolean,
        showValues: boolean,
    ) {
        // if (this.hover) {
        //     ctx.strokeStyle = "green";
        //     ctx.strokeRect(this.x - this.width / 2, this.y - this.height / 2, this.width, this.height);
        // }
        if (showHeatmap) {
            ctx.filter = "blur(10px) contrast(5) ";
            ctx.beginPath();
            ctx.ellipse(
                this.x,
                this.y,
                this.width / 2,
                this.height / 2,
                0,
                0,
                Math.PI * 2,
            );

            ctx.fillStyle = this.color;
            ctx.fill();
            ctx.lineWidth = 4;
            ctx.strokeStyle = this.color;
            ctx.stroke();
            ctx.closePath();
            ctx.filter = "none";
        }
        if (showValues) {
            ctx.strokeStyle = "black";
            ctx.lineWidth = 1;

            ctx.strokeText(`${this.value}`, this.x, this.y);
        }
    }
}
