





















































import DeviceListComponent from "@/components/OnlineDevices.vue";
import { WetMapFlags } from "@/components/RendererModels";
import SquaredMap, { ImageDefinition } from "@/components/SquaredMap.vue";
import { TileOptions } from "@/services/devices";
import Vue from "vue";
import Component from "vue-class-component";

class ColorDefinition {
  color: string;
  value: number;
}
@Component({
  components: {
    SquaredMap: () => Promise.resolve(SquaredMap),
    DeviceList: () => Promise.resolve(DeviceListComponent),
  },
})
export default class DemoViewer extends Vue {
  demoImage: any = require("@/assets/buildings/model_3d.jpeg");
  wetmapflags: WetMapFlags = new WetMapFlags();

  radius: number = 5;
  max: number = 150;
  blur: number = 2;
  board = "1";
  items = ["1", "2", "3", "4", "5"];
  newColor: string = "";
  offsetX: number = 0;
  offsetY: number = 0;
  hoverValue: number = 0;
  hoverLocation: string = "";
  tileOptions: TileOptions = {
    width: 10,
    height: 10,
    border: 2,
  } as TileOptions;

  gradientModel: ColorDefinition[] = [];

  masks: ImageDefinition[] = [
    {
      src: require("@/assets/buildings/model/roof1-negative-mask.png"),
      options: { row: 4, col: 0, toRow: 7, toCol: 7 },
    },
    {
      src: require("@/assets/buildings/model/roof2-negative-mask.png"),
      options: { row: 4, col: 1, toRow: 5, toCol: 3 },
    },
    {
      src: require("@/assets/buildings/model/roof3-negative-mask.png"),
      options: { row: 0, col: 5, toRow: 2, toCol: 7 },
    },
    {
      src: require("@/assets/buildings/model/roof4-negative-mask.png"),
      options: { row: 1, col: 3, toRow: 2, toCol: 3 },
    },
  ];

  panelHover(item: any) {
    if (item && item.value > -1) {
      this.hoverValue = item.value;
      this.hoverLocation = item.location;
    }
  }
  panelOut() {
    this.hoverLocation = "";
    this.hoverValue = 0;
  }

  mounted() {
    this.load();
  }
  load() {
    let settings = localStorage.getItem("settings");
    if (!settings) {
      console.error("settings missing.");
      return;
    }
    const ob = JSON.parse(settings);
    this.tileOptions = ob.tileOptions;
    this.gradientModel = ob.gradientModel;
    this.offsetX = ob.offsetX;
    this.offsetY = ob.offsetY;
    this.radius = ob.radius;
    this.blur = ob.blur;
    this.wetmapflags = ob.wetmapflags || this.wetmapflags;
    this.max = ob.max;
  }

  transform(value: number) {
    return (1 / this.max) * value;
  }
  sort() {
    this.gradientModel.sort((x, b) => x.value - b.value);
  }
  get gradientOptions() {
    var ob: any = {};
    this.gradientModel.forEach((c) => {
      ob[c.color] = this.transform(c.value);
    });
    return ob;
  }
}
