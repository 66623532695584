import { AppModule, AppRouteConfig } from "@/core/appTypes";

import AccountList from './AccountList.vue';
import AccountDetail from './AccountDetail.vue';

import * as icons from "@mdi/js";
export const accountModule: AppModule = {
    routes: [
        {
            path: '/admin/accounts',
            name: 'accounts',
            visible: true,
            component: AccountList,
            icon: icons.mdiAccountMultiple,
            label: 'Accounts',
            order: 50
        },
        {
            path: '/admin/account/:id',
            name: 'AccountDetail',
            visible: false,
            component: AccountDetail,
            icon: icons.mdiAccount,
            label: 'AccountDetail',
            order: 50
        },
    ]
};