










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InfoTag extends Vue {
  @Prop({ default: "Add some text here" }) text: string;
  @Prop({ default: "primary" }) color: string;
}
