
import { AlarmApiFactory, Configuration, PagedCollectionFilter } from "@/core/webapi";
import Vue from "vue";
import { AxiosFactory } from "../core";
const api = AlarmApiFactory(new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create());

export const AlarmService = new Vue({
    methods: {
        async getAlarms(model: PagedCollectionFilter) {
            return await (await api.apiAdminAlarmsListPost(model)).data;
        },
        async getAlarm(id: number) {
            return await (await api.apiAdminAlarmsIdGet(id)).data;
        },
        async resolveAlarm(id: number, statusId: number) {
            return await (await api.apiAdminAlarmsIdResolvePost(id, { statusId })).data;
        },
        async getMyAlarms() {
            return await (await api.apiAdminAlarmsMyAlarmsGet()).data;
        }
    }
}); 