/* tslint:disable */
/* eslint-disable */
/**
 * Aquatrace Onedrop
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AccountModel
 */
export class AccountModel {
  /**
   *
   * @type {string}
   * @memberof AccountModel
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountModel
   */
  userName?: string | null;
  /**
   *
   * @type {number}
   * @memberof AccountModel
   */
  userId: number;
  /**
   *
   * @type {boolean}
   * @memberof AccountModel
   */
  locked: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof AccountModel
   */
  roles?: Array<number> | null;
  /**
   *
   * @type {boolean}
   * @memberof AccountModel
   */
  emailConfirmed: boolean;
  /**
   *
   * @type {Array<SimpleDeviceModel>}
   * @memberof AccountModel
   */
  devices?: Array<SimpleDeviceModel> | null;

  constructor(data?: Partial<AccountModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AdminLookupList
 */
export class AdminLookupList {
  /**
   *
   * @type {Array<Lookup>}
   * @memberof AdminLookupList
   */
  roles?: Array<Lookup> | null;
  /**
   *
   * @type {Array<StringLookup>}
   * @memberof AdminLookupList
   */
  timezones?: Array<StringLookup> | null;

  constructor(data?: Partial<AdminLookupList>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AggregationSegment
 */
export class AggregationSegment {
  /**
   *
   * @type {string}
   * @memberof AggregationSegment
   */
  deviceId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AggregationSegment
   */
  groupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AggregationSegment
   */
  segmentStart: string;
  /**
   *
   * @type {string}
   * @memberof AggregationSegment
   */
  segmentEnd: string;
  /**
   *
   * @type {string}
   * @memberof AggregationSegment
   */
  prefix?: string | null;

  constructor(data?: Partial<AggregationSegment>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AlarmDetailModel
 */
export class AlarmDetailModel {
  /**
   *
   * @type {number}
   * @memberof AlarmDetailModel
   */
  alarmId: number;
  /**
   *
   * @type {number}
   * @memberof AlarmDetailModel
   */
  deviceId: number;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  hardwareId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  groupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  end?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  firstResolvedEvent?: string | null;
  /**
   *
   * @type {number}
   * @memberof AlarmDetailModel
   */
  maxPanelsAffected: number;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  status?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmDetailModel
   */
  type?: string | null;
  /**
   *
   * @type {Array<AlarmHistoryRow>}
   * @memberof AlarmDetailModel
   */
  alarmHistoryRows?: Array<AlarmHistoryRow> | null;

  constructor(data?: Partial<AlarmDetailModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AlarmHistoryRow
 */
export class AlarmHistoryRow {
  /**
   *
   * @type {number}
   * @memberof AlarmHistoryRow
   */
  alarmHistoryId: number;
  /**
   *
   * @type {string}
   * @memberof AlarmHistoryRow
   */
  eventType?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmHistoryRow
   */
  createdAt: string;
  /**
   *
   * @type {number}
   * @memberof AlarmHistoryRow
   */
  createdBy: number;
  /**
   *
   * @type {string}
   * @memberof AlarmHistoryRow
   */
  createdByUser?: string | null;

  constructor(data?: Partial<AlarmHistoryRow>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AlarmResolutionModel
 */
export class AlarmResolutionModel {
  /**
   *
   * @type {number}
   * @memberof AlarmResolutionModel
   */
  statusId: number;

  constructor(data?: Partial<AlarmResolutionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AlarmRow
 */
export class AlarmRow {
  /**
   *
   * @type {number}
   * @memberof AlarmRow
   */
  alarmId: number;
  /**
   *
   * @type {number}
   * @memberof AlarmRow
   */
  deviceId: number;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  hardwareId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  groupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  end?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  firstResolvedEvent?: string | null;
  /**
   *
   * @type {number}
   * @memberof AlarmRow
   */
  maxPanelsAffected: number;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  status?: string | null;
  /**
   *
   * @type {string}
   * @memberof AlarmRow
   */
  type?: string | null;

  constructor(data?: Partial<AlarmRow>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AlarmRowPagedCollectionModel
 */
export class AlarmRowPagedCollectionModel {
  /**
   *
   * @type {number}
   * @memberof AlarmRowPagedCollectionModel
   */
  pageCount: number;
  /**
   *
   * @type {number}
   * @memberof AlarmRowPagedCollectionModel
   */
  recordCount: number;
  /**
   *
   * @type {Array<AlarmRow>}
   * @memberof AlarmRowPagedCollectionModel
   */
  items?: Array<AlarmRow> | null;

  constructor(data?: Partial<AlarmRowPagedCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ChangePasswordRequestModel
 */
export class ChangePasswordRequestModel {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequestModel
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequestModel
   */
  currentPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequestModel
   */
  newPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequestModel
   */
  confirmPassword?: string | null;

  constructor(data?: Partial<ChangePasswordRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ConfirmEmailAddressRequestModel
 */
export class ConfirmEmailAddressRequestModel {
  /**
   *
   * @type {string}
   * @memberof ConfirmEmailAddressRequestModel
   */
  token?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfirmEmailAddressRequestModel
   */
  email?: string | null;

  constructor(data?: Partial<ConfirmEmailAddressRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ConfirmEmailAddressResultModel
 */
export class ConfirmEmailAddressResultModel {
  /**
   *
   * @type {StringTokenWrapper}
   * @memberof ConfirmEmailAddressResultModel
   */
  token: StringTokenWrapper;
  /**
   *
   * @type {string}
   * @memberof ConfirmEmailAddressResultModel
   */
  email?: string | null;

  constructor(data?: Partial<ConfirmEmailAddressResultModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DeviceLookupList
 */
export class DeviceLookupList {
  /**
   *
   * @type {Array<Lookup>}
   * @memberof DeviceLookupList
   */
  statusList?: Array<Lookup> | null;

  constructor(data?: Partial<DeviceLookupList>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DeviceModel
 */
export class DeviceModel {
  /**
   *
   * @type {number}
   * @memberof DeviceModel
   */
  deviceId: number;
  /**
   *
   * @type {number}
   * @memberof DeviceModel
   */
  statusId: number;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  statusName?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  hardwareId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DeviceModel
   */
  debugEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeviceModel
   */
  traceEnabled: boolean;
  /**
   *
   * @type {number}
   * @memberof DeviceModel
   */
  threshold: number;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  mask?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  photo?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  mapSettings?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  groupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  centers?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DeviceModel
   */
  isOffline: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeviceModel
   */
  isAlarm: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceModel
   */
  lastSeenAt?: string | null;

  constructor(data?: Partial<DeviceModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DeviceModelPagedCollectionModel
 */
export class DeviceModelPagedCollectionModel {
  /**
   *
   * @type {number}
   * @memberof DeviceModelPagedCollectionModel
   */
  pageCount: number;
  /**
   *
   * @type {number}
   * @memberof DeviceModelPagedCollectionModel
   */
  recordCount: number;
  /**
   *
   * @type {Array<DeviceModel>}
   * @memberof DeviceModelPagedCollectionModel
   */
  items?: Array<DeviceModel> | null;

  constructor(data?: Partial<DeviceModelPagedCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DeviceNodeModel
 */
export class DeviceNodeModel {
  /**
   *
   * @type {number}
   * @memberof DeviceNodeModel
   */
  nodeId: number;
  /**
   *
   * @type {number}
   * @memberof DeviceNodeModel
   */
  deviceId: number;
  /**
   *
   * @type {number}
   * @memberof DeviceNodeModel
   */
  wetmapArrayOffset: number;
  /**
   *
   * @type {number}
   * @memberof DeviceNodeModel
   */
  columnOffset: number;
  /**
   *
   * @type {number}
   * @memberof DeviceNodeModel
   */
  rowOffset: number;

  constructor(data?: Partial<DeviceNodeModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DeviceTableRowModel
 */
export class DeviceTableRowModel {
  /**
   *
   * @type {string}
   * @memberof DeviceTableRowModel
   */
  partitionKey?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceTableRowModel
   */
  rowKey?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceTableRowModel
   */
  timestamp?: string | null;
  /**
   *
   * @type {object}
   * @memberof DeviceTableRowModel
   */
  eTag: object;
  /**
   *
   * @type {string}
   * @memberof DeviceTableRowModel
   */
  deviceId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceTableRowModel
   */
  wetmapArray?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceTableRowModel
   */
  pretriggerData?: string | null;
  /**
   *
   * @type {number}
   * @memberof DeviceTableRowModel
   */
  recordAggregated: number;

  constructor(data?: Partial<DeviceTableRowModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DeviceWithNodesModel
 */
export class DeviceWithNodesModel {
  /**
   *
   * @type {number}
   * @memberof DeviceWithNodesModel
   */
  deviceId: number;
  /**
   *
   * @type {number}
   * @memberof DeviceWithNodesModel
   */
  statusId: number;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  statusName?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  hardwareId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DeviceWithNodesModel
   */
  debugEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeviceWithNodesModel
   */
  traceEnabled: boolean;
  /**
   *
   * @type {number}
   * @memberof DeviceWithNodesModel
   */
  threshold: number;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  mask?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  photo?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  centers?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DeviceWithNodesModel
   */
  isOffline: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeviceWithNodesModel
   */
  isAlarm: boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  lastSeenAt?: string | null;
  /**
   *
   * @type {Array<DeviceNodeModel>}
   * @memberof DeviceWithNodesModel
   */
  nodes?: Array<DeviceNodeModel> | null;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  mapSettings?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  planSettings?: string | null;
  /**
   *
   * @type {string}
   * @memberof DeviceWithNodesModel
   */
  groupId?: string | null;
  /**
   *
   * @type {Array<UserDeviceModel>}
   * @memberof DeviceWithNodesModel
   */
  usersWithAccess?: Array<UserDeviceModel> | null;

  constructor(data?: Partial<DeviceWithNodesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FieldFilterModel
 */
export class FieldFilterModel {
  /**
   *
   * @type {string}
   * @memberof FieldFilterModel
   */
  fieldName?: string | null;
  /**
   *
   * @type {FieldFilterType}
   * @memberof FieldFilterModel
   */
  filterType: FieldFilterType;
  /**
   *
   * @type {string}
   * @memberof FieldFilterModel
   */
  value?: string | null;

  constructor(data?: Partial<FieldFilterModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @enum {string}
 */

export const FieldFilterType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type FieldFilterType =
  typeof FieldFilterType[keyof typeof FieldFilterType];

/**
 *
 * @export
 * @interface FullAggregationExportRequest
 */
export class FullAggregationExportRequest {
  /**
   *
   * @type {string}
   * @memberof FullAggregationExportRequest
   */
  segmentStart: string;
  /**
   *
   * @type {string}
   * @memberof FullAggregationExportRequest
   */
  segmentEnd: string;
  /**
   *
   * @type {string}
   * @memberof FullAggregationExportRequest
   */
  prefix?: string | null;

  constructor(data?: Partial<FullAggregationExportRequest>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FullAggregationSegment
 */
export class FullAggregationSegment {
  /**
   *
   * @type {string}
   * @memberof FullAggregationSegment
   */
  segmentStart: string;
  /**
   *
   * @type {string}
   * @memberof FullAggregationSegment
   */
  segmentEnd: string;

  constructor(data?: Partial<FullAggregationSegment>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface LoginModel
 */
export class LoginModel {
  /**
   *
   * @type {string}
   * @memberof LoginModel
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof LoginModel
   */
  password: string;

  constructor(data?: Partial<LoginModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface Lookup
 */
export class Lookup {
  /**
   *
   * @type {string}
   * @memberof Lookup
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof Lookup
   */
  key: number;

  constructor(data?: Partial<Lookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PagedCollectionFilter
 */
export class PagedCollectionFilter {
  /**
   *
   * @type {number}
   * @memberof PagedCollectionFilter
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PagedCollectionFilter
   */
  pageSize: number;
  /**
   *
   * @type {string}
   * @memberof PagedCollectionFilter
   */
  search?: string | null;
  /**
   *
   * @type {string}
   * @memberof PagedCollectionFilter
   */
  sortBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof PagedCollectionFilter
   */
  sortOperator?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PagedCollectionFilter
   */
  includeDeleted: boolean;
  /**
   *
   * @type {Array<FieldFilterModel>}
   * @memberof PagedCollectionFilter
   */
  filters?: Array<FieldFilterModel> | null;

  constructor(data?: Partial<PagedCollectionFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface RefreshTokenRequestModel
 */
export class RefreshTokenRequestModel {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRequestModel
   */
  refreshToken?: string | null;

  constructor(data?: Partial<RefreshTokenRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ResetPasswordRequestModel
 */
export class ResetPasswordRequestModel {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequestModel
   */
  email?: string | null;

  constructor(data?: Partial<ResetPasswordRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface RestorePasswordRequestModel
 */
export class RestorePasswordRequestModel {
  /**
   *
   * @type {string}
   * @memberof RestorePasswordRequestModel
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof RestorePasswordRequestModel
   */
  newPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof RestorePasswordRequestModel
   */
  confirmPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof RestorePasswordRequestModel
   */
  token?: string | null;

  constructor(data?: Partial<RestorePasswordRequestModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SimpleDeviceModel
 */
export class SimpleDeviceModel {
  /**
   *
   * @type {number}
   * @memberof SimpleDeviceModel
   */
  deviceId: number;
  /**
   *
   * @type {string}
   * @memberof SimpleDeviceModel
   */
  hardwareId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimpleDeviceModel
   */
  groupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimpleDeviceModel
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof SimpleDeviceModel
   */
  statusId: number;

  constructor(data?: Partial<SimpleDeviceModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SimpleDeviceModelPagedCollectionModel
 */
export class SimpleDeviceModelPagedCollectionModel {
  /**
   *
   * @type {number}
   * @memberof SimpleDeviceModelPagedCollectionModel
   */
  pageCount: number;
  /**
   *
   * @type {number}
   * @memberof SimpleDeviceModelPagedCollectionModel
   */
  recordCount: number;
  /**
   *
   * @type {Array<SimpleDeviceModel>}
   * @memberof SimpleDeviceModelPagedCollectionModel
   */
  items?: Array<SimpleDeviceModel> | null;

  constructor(data?: Partial<SimpleDeviceModelPagedCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StringLookup
 */
export class StringLookup {
  /**
   *
   * @type {string}
   * @memberof StringLookup
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof StringLookup
   */
  key?: string | null;

  constructor(data?: Partial<StringLookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface StringTokenWrapper
 */
export class StringTokenWrapper {
  /**
   *
   * @type {string}
   * @memberof StringTokenWrapper
   */
  token?: string | null;

  constructor(data?: Partial<StringTokenWrapper>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface Token
 */
export class Token {
  /**
   *
   * @type {number}
   * @memberof Token
   */
  expiresIn: number;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  accessToken?: string | null;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  refreshToken?: string | null;

  constructor(data?: Partial<Token>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TokenWrapper
 */
export class TokenWrapper {
  /**
   *
   * @type {Token}
   * @memberof TokenWrapper
   */
  token: Token;

  constructor(data?: Partial<TokenWrapper>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserDeviceModel
 */
export class UserDeviceModel {
  /**
   *
   * @type {string}
   * @memberof UserDeviceModel
   */
  userName?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserDeviceModel
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof UserDeviceModel
   */
  userDeviceId: number;

  constructor(data?: Partial<UserDeviceModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserRow
 */
export class UserRow {
  /**
   *
   * @type {number}
   * @memberof UserRow
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof UserRow
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserRow
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserRow
   */
  locked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserRow
   */
  isAdmin: boolean;

  constructor(data?: Partial<UserRow>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserRowPagedCollectionModel
 */
export class UserRowPagedCollectionModel {
  /**
   *
   * @type {number}
   * @memberof UserRowPagedCollectionModel
   */
  pageCount: number;
  /**
   *
   * @type {number}
   * @memberof UserRowPagedCollectionModel
   */
  recordCount: number;
  /**
   *
   * @type {Array<UserRow>}
   * @memberof UserRowPagedCollectionModel
   */
  items?: Array<UserRow> | null;

  constructor(data?: Partial<UserRowPagedCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAccountsIdGet: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("apiAdminAccountsIdGet", "id", id);
      const localVarPath = `/api/admin/accounts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAccountsListPost: async (
      pagedCollectionFilter?: PagedCollectionFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/accounts/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagedCollectionFilter,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAccountsLookupsGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/accounts/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAccountsIdGet(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAccountsIdGet(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAccountsListPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserRowPagedCollectionModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAccountsListPost(
          pagedCollectionFilter,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAccountsLookupsGet(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AdminLookupList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAccountsLookupsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAccountsIdGet(
      id: number,
      options?: any
    ): AxiosPromise<AccountModel> {
      return localVarFp
        .apiAdminAccountsIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAccountsListPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: any
    ): AxiosPromise<UserRowPagedCollectionModel> {
      return localVarFp
        .apiAdminAccountsListPost(pagedCollectionFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAccountsLookupsGet(options?: any): AxiosPromise<AdminLookupList> {
      return localVarFp
        .apiAdminAccountsLookupsGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - interface
 * @export
 * @interface AccountApi
 */
export interface AccountApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  apiAdminAccountsIdGet(
    id: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<AccountModel>;

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  apiAdminAccountsListPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ): AxiosPromise<UserRowPagedCollectionModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  apiAdminAccountsLookupsGet(
    options?: AxiosRequestConfig
  ): AxiosPromise<AdminLookupList>;
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI implements AccountApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAdminAccountsIdGet(id: number, options?: AxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .apiAdminAccountsIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAdminAccountsListPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ) {
    return AccountApiFp(this.configuration)
      .apiAdminAccountsListPost(pagedCollectionFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAdminAccountsLookupsGet(options?: AxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .apiAdminAccountsLookupsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AggregationApi - axios parameter creator
 * @export
 */
export const AggregationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [key]
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationExportPost: async (
      key?: string,
      aggregationSegment?: AggregationSegment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/data/aggregation/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      if (key !== undefined) {
        localVarQueryParameter["key"] = key;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        aggregationSegment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [key]
     * @param {FullAggregationExportRequest} [fullAggregationExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationFullexportPost: async (
      key?: string,
      fullAggregationExportRequest?: FullAggregationExportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/data/aggregation/fullexport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      if (key !== undefined) {
        localVarQueryParameter["key"] = key;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fullAggregationExportRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [key]
     * @param {FullAggregationSegment} [fullAggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationFullrollupPost: async (
      key?: string,
      fullAggregationSegment?: FullAggregationSegment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/data/aggregation/fullrollup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      if (key !== undefined) {
        localVarQueryParameter["key"] = key;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fullAggregationSegment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationQueryAggregatesPost: async (
      aggregationSegment?: AggregationSegment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/data/aggregation/queryAggregates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        aggregationSegment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationQueryAlertsPost: async (
      aggregationSegment?: AggregationSegment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/data/aggregation/queryAlerts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        aggregationSegment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [key]
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationRollupPost: async (
      key?: string,
      aggregationSegment?: AggregationSegment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/data/aggregation/rollup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      if (key !== undefined) {
        localVarQueryParameter["key"] = key;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        aggregationSegment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AggregationApi - functional programming interface
 * @export
 */
export const AggregationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AggregationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [key]
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDataAggregationExportPost(
      key?: string,
      aggregationSegment?: AggregationSegment,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDataAggregationExportPost(
          key,
          aggregationSegment,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [key]
     * @param {FullAggregationExportRequest} [fullAggregationExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDataAggregationFullexportPost(
      key?: string,
      fullAggregationExportRequest?: FullAggregationExportRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDataAggregationFullexportPost(
          key,
          fullAggregationExportRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [key]
     * @param {FullAggregationSegment} [fullAggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDataAggregationFullrollupPost(
      key?: string,
      fullAggregationSegment?: FullAggregationSegment,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceTableRowModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDataAggregationFullrollupPost(
          key,
          fullAggregationSegment,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDataAggregationQueryAggregatesPost(
      aggregationSegment?: AggregationSegment,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DeviceTableRowModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDataAggregationQueryAggregatesPost(
          aggregationSegment,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDataAggregationQueryAlertsPost(
      aggregationSegment?: AggregationSegment,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DeviceTableRowModel>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDataAggregationQueryAlertsPost(
          aggregationSegment,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [key]
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiDataAggregationRollupPost(
      key?: string,
      aggregationSegment?: AggregationSegment,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceTableRowModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiDataAggregationRollupPost(
          key,
          aggregationSegment,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AggregationApi - factory interface
 * @export
 */
export const AggregationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AggregationApiFp(configuration);
  return {
    /**
     *
     * @param {string} [key]
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationExportPost(
      key?: string,
      aggregationSegment?: AggregationSegment,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .apiDataAggregationExportPost(key, aggregationSegment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [key]
     * @param {FullAggregationExportRequest} [fullAggregationExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationFullexportPost(
      key?: string,
      fullAggregationExportRequest?: FullAggregationExportRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .apiDataAggregationFullexportPost(
          key,
          fullAggregationExportRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [key]
     * @param {FullAggregationSegment} [fullAggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationFullrollupPost(
      key?: string,
      fullAggregationSegment?: FullAggregationSegment,
      options?: any
    ): AxiosPromise<DeviceTableRowModel> {
      return localVarFp
        .apiDataAggregationFullrollupPost(key, fullAggregationSegment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationQueryAggregatesPost(
      aggregationSegment?: AggregationSegment,
      options?: any
    ): AxiosPromise<Array<DeviceTableRowModel>> {
      return localVarFp
        .apiDataAggregationQueryAggregatesPost(aggregationSegment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationQueryAlertsPost(
      aggregationSegment?: AggregationSegment,
      options?: any
    ): AxiosPromise<Array<DeviceTableRowModel>> {
      return localVarFp
        .apiDataAggregationQueryAlertsPost(aggregationSegment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [key]
     * @param {AggregationSegment} [aggregationSegment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiDataAggregationRollupPost(
      key?: string,
      aggregationSegment?: AggregationSegment,
      options?: any
    ): AxiosPromise<DeviceTableRowModel> {
      return localVarFp
        .apiDataAggregationRollupPost(key, aggregationSegment, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AggregationApi - interface
 * @export
 * @interface AggregationApi
 */
export interface AggregationApiInterface {
  /**
   *
   * @param {string} [key]
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApiInterface
   */
  apiDataAggregationExportPost(
    key?: string,
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ): AxiosPromise<string>;

  /**
   *
   * @param {string} [key]
   * @param {FullAggregationExportRequest} [fullAggregationExportRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApiInterface
   */
  apiDataAggregationFullexportPost(
    key?: string,
    fullAggregationExportRequest?: FullAggregationExportRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<string>;

  /**
   *
   * @param {string} [key]
   * @param {FullAggregationSegment} [fullAggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApiInterface
   */
  apiDataAggregationFullrollupPost(
    key?: string,
    fullAggregationSegment?: FullAggregationSegment,
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceTableRowModel>;

  /**
   *
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApiInterface
   */
  apiDataAggregationQueryAggregatesPost(
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<DeviceTableRowModel>>;

  /**
   *
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApiInterface
   */
  apiDataAggregationQueryAlertsPost(
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<DeviceTableRowModel>>;

  /**
   *
   * @param {string} [key]
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApiInterface
   */
  apiDataAggregationRollupPost(
    key?: string,
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceTableRowModel>;
}

/**
 * AggregationApi - object-oriented interface
 * @export
 * @class AggregationApi
 * @extends {BaseAPI}
 */
export class AggregationApi extends BaseAPI implements AggregationApiInterface {
  /**
   *
   * @param {string} [key]
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApi
   */
  public apiDataAggregationExportPost(
    key?: string,
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ) {
    return AggregationApiFp(this.configuration)
      .apiDataAggregationExportPost(key, aggregationSegment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [key]
   * @param {FullAggregationExportRequest} [fullAggregationExportRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApi
   */
  public apiDataAggregationFullexportPost(
    key?: string,
    fullAggregationExportRequest?: FullAggregationExportRequest,
    options?: AxiosRequestConfig
  ) {
    return AggregationApiFp(this.configuration)
      .apiDataAggregationFullexportPost(
        key,
        fullAggregationExportRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [key]
   * @param {FullAggregationSegment} [fullAggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApi
   */
  public apiDataAggregationFullrollupPost(
    key?: string,
    fullAggregationSegment?: FullAggregationSegment,
    options?: AxiosRequestConfig
  ) {
    return AggregationApiFp(this.configuration)
      .apiDataAggregationFullrollupPost(key, fullAggregationSegment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApi
   */
  public apiDataAggregationQueryAggregatesPost(
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ) {
    return AggregationApiFp(this.configuration)
      .apiDataAggregationQueryAggregatesPost(aggregationSegment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApi
   */
  public apiDataAggregationQueryAlertsPost(
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ) {
    return AggregationApiFp(this.configuration)
      .apiDataAggregationQueryAlertsPost(aggregationSegment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [key]
   * @param {AggregationSegment} [aggregationSegment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AggregationApi
   */
  public apiDataAggregationRollupPost(
    key?: string,
    aggregationSegment?: AggregationSegment,
    options?: AxiosRequestConfig
  ) {
    return AggregationApiFp(this.configuration)
      .apiDataAggregationRollupPost(key, aggregationSegment, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AlarmApi - axios parameter creator
 * @export
 */
export const AlarmApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsIdGet: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("apiAdminAlarmsIdGet", "id", id);
      const localVarPath = `/api/admin/alarms/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AlarmResolutionModel} [alarmResolutionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsIdResolvePost: async (
      id: number,
      alarmResolutionModel?: AlarmResolutionModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("apiAdminAlarmsIdResolvePost", "id", id);
      const localVarPath = `/api/admin/alarms/{id}/resolve`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        alarmResolutionModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsListPost: async (
      pagedCollectionFilter?: PagedCollectionFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/alarms/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagedCollectionFilter,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsMyAlarmsGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/alarms/myAlarms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AlarmApi - functional programming interface
 * @export
 */
export const AlarmApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AlarmApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAlarmsIdGet(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AlarmDetailModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAlarmsIdGet(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {AlarmResolutionModel} [alarmResolutionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAlarmsIdResolvePost(
      id: number,
      alarmResolutionModel?: AlarmResolutionModel,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAlarmsIdResolvePost(
          id,
          alarmResolutionModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAlarmsListPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AlarmRowPagedCollectionModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAlarmsListPost(
          pagedCollectionFilter,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminAlarmsMyAlarmsGet(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AlarmRowPagedCollectionModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminAlarmsMyAlarmsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AlarmApi - factory interface
 * @export
 */
export const AlarmApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AlarmApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsIdGet(
      id: number,
      options?: any
    ): AxiosPromise<AlarmDetailModel> {
      return localVarFp
        .apiAdminAlarmsIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AlarmResolutionModel} [alarmResolutionModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsIdResolvePost(
      id: number,
      alarmResolutionModel?: AlarmResolutionModel,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .apiAdminAlarmsIdResolvePost(id, alarmResolutionModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsListPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: any
    ): AxiosPromise<AlarmRowPagedCollectionModel> {
      return localVarFp
        .apiAdminAlarmsListPost(pagedCollectionFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminAlarmsMyAlarmsGet(
      options?: any
    ): AxiosPromise<AlarmRowPagedCollectionModel> {
      return localVarFp
        .apiAdminAlarmsMyAlarmsGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AlarmApi - interface
 * @export
 * @interface AlarmApi
 */
export interface AlarmApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApiInterface
   */
  apiAdminAlarmsIdGet(
    id: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<AlarmDetailModel>;

  /**
   *
   * @param {number} id
   * @param {AlarmResolutionModel} [alarmResolutionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApiInterface
   */
  apiAdminAlarmsIdResolvePost(
    id: number,
    alarmResolutionModel?: AlarmResolutionModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<string>;

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApiInterface
   */
  apiAdminAlarmsListPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ): AxiosPromise<AlarmRowPagedCollectionModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApiInterface
   */
  apiAdminAlarmsMyAlarmsGet(
    options?: AxiosRequestConfig
  ): AxiosPromise<AlarmRowPagedCollectionModel>;
}

/**
 * AlarmApi - object-oriented interface
 * @export
 * @class AlarmApi
 * @extends {BaseAPI}
 */
export class AlarmApi extends BaseAPI implements AlarmApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApi
   */
  public apiAdminAlarmsIdGet(id: number, options?: AxiosRequestConfig) {
    return AlarmApiFp(this.configuration)
      .apiAdminAlarmsIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AlarmResolutionModel} [alarmResolutionModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApi
   */
  public apiAdminAlarmsIdResolvePost(
    id: number,
    alarmResolutionModel?: AlarmResolutionModel,
    options?: AxiosRequestConfig
  ) {
    return AlarmApiFp(this.configuration)
      .apiAdminAlarmsIdResolvePost(id, alarmResolutionModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApi
   */
  public apiAdminAlarmsListPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ) {
    return AlarmApiFp(this.configuration)
      .apiAdminAlarmsListPost(pagedCollectionFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmApi
   */
  public apiAdminAlarmsMyAlarmsGet(options?: AxiosRequestConfig) {
    return AlarmApiFp(this.configuration)
      .apiAdminAlarmsMyAlarmsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {ChangePasswordRequestModel} [changePasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthChangePasswordPost: async (
      changePasswordRequestModel?: ChangePasswordRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/changePassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePasswordRequestModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConfirmEmailAddressRequestModel} [confirmEmailAddressRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthConfirmEmailPost: async (
      confirmEmailAddressRequestModel?: ConfirmEmailAddressRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/confirmEmail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        confirmEmailAddressRequestModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RestorePasswordRequestModel} [restorePasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthForgotPasswordPost: async (
      restorePasswordRequestModel?: RestorePasswordRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/forgotPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        restorePasswordRequestModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResetPasswordRequestModel} [resetPasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthForgotPasswordRequestPost: async (
      resetPasswordRequestModel?: ResetPasswordRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/forgotPassword/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordRequestModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthLoginPost: async (
      loginModel?: LoginModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthRefreshTokenGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RefreshTokenRequestModel} [refreshTokenRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthRefreshTokenPost: async (
      refreshTokenRequestModel?: RefreshTokenRequestModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshTokenRequestModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthSendAccountConfirmationPost: async (
      email?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/sendAccountConfirmation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      if (email !== undefined) {
        localVarQueryParameter["email"] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ChangePasswordRequestModel} [changePasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthChangePasswordPost(
      changePasswordRequestModel?: ChangePasswordRequestModel,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthChangePasswordPost(
          changePasswordRequestModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {ConfirmEmailAddressRequestModel} [confirmEmailAddressRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthConfirmEmailPost(
      confirmEmailAddressRequestModel?: ConfirmEmailAddressRequestModel,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ConfirmEmailAddressResultModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthConfirmEmailPost(
          confirmEmailAddressRequestModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {RestorePasswordRequestModel} [restorePasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthForgotPasswordPost(
      restorePasswordRequestModel?: RestorePasswordRequestModel,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWrapper>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthForgotPasswordPost(
          restorePasswordRequestModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {ResetPasswordRequestModel} [resetPasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthForgotPasswordRequestPost(
      resetPasswordRequestModel?: ResetPasswordRequestModel,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthForgotPasswordRequestPost(
          resetPasswordRequestModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthLoginPost(
      loginModel?: LoginModel,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWrapper>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthLoginPost(loginModel, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthRefreshTokenGet(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWrapper>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthRefreshTokenGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {RefreshTokenRequestModel} [refreshTokenRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthRefreshTokenPost(
      refreshTokenRequestModel?: RefreshTokenRequestModel,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWrapper>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthRefreshTokenPost(
          refreshTokenRequestModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiV1AuthSendAccountConfirmationPost(
      email?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiV1AuthSendAccountConfirmationPost(
          email,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {ChangePasswordRequestModel} [changePasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthChangePasswordPost(
      changePasswordRequestModel?: ChangePasswordRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .apiV1AuthChangePasswordPost(changePasswordRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConfirmEmailAddressRequestModel} [confirmEmailAddressRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthConfirmEmailPost(
      confirmEmailAddressRequestModel?: ConfirmEmailAddressRequestModel,
      options?: any
    ): AxiosPromise<ConfirmEmailAddressResultModel> {
      return localVarFp
        .apiV1AuthConfirmEmailPost(confirmEmailAddressRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RestorePasswordRequestModel} [restorePasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthForgotPasswordPost(
      restorePasswordRequestModel?: RestorePasswordRequestModel,
      options?: any
    ): AxiosPromise<TokenWrapper> {
      return localVarFp
        .apiV1AuthForgotPasswordPost(restorePasswordRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResetPasswordRequestModel} [resetPasswordRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthForgotPasswordRequestPost(
      resetPasswordRequestModel?: ResetPasswordRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .apiV1AuthForgotPasswordRequestPost(resetPasswordRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginModel} [loginModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthLoginPost(
      loginModel?: LoginModel,
      options?: any
    ): AxiosPromise<TokenWrapper> {
      return localVarFp
        .apiV1AuthLoginPost(loginModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthRefreshTokenGet(options?: any): AxiosPromise<TokenWrapper> {
      return localVarFp
        .apiV1AuthRefreshTokenGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RefreshTokenRequestModel} [refreshTokenRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthRefreshTokenPost(
      refreshTokenRequestModel?: RefreshTokenRequestModel,
      options?: any
    ): AxiosPromise<TokenWrapper> {
      return localVarFp
        .apiV1AuthRefreshTokenPost(refreshTokenRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiV1AuthSendAccountConfirmationPost(
      email?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .apiV1AuthSendAccountConfirmationPost(email, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
  /**
   *
   * @param {ChangePasswordRequestModel} [changePasswordRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthChangePasswordPost(
    changePasswordRequestModel?: ChangePasswordRequestModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<string>;

  /**
   *
   * @param {ConfirmEmailAddressRequestModel} [confirmEmailAddressRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthConfirmEmailPost(
    confirmEmailAddressRequestModel?: ConfirmEmailAddressRequestModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<ConfirmEmailAddressResultModel>;

  /**
   *
   * @param {RestorePasswordRequestModel} [restorePasswordRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthForgotPasswordPost(
    restorePasswordRequestModel?: RestorePasswordRequestModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<TokenWrapper>;

  /**
   *
   * @param {ResetPasswordRequestModel} [resetPasswordRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthForgotPasswordRequestPost(
    resetPasswordRequestModel?: ResetPasswordRequestModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<string>;

  /**
   *
   * @param {LoginModel} [loginModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthLoginPost(
    loginModel?: LoginModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<TokenWrapper>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthRefreshTokenGet(
    options?: AxiosRequestConfig
  ): AxiosPromise<TokenWrapper>;

  /**
   *
   * @param {RefreshTokenRequestModel} [refreshTokenRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthRefreshTokenPost(
    refreshTokenRequestModel?: RefreshTokenRequestModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<TokenWrapper>;

  /**
   *
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  apiV1AuthSendAccountConfirmationPost(
    email?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<string>;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
  /**
   *
   * @param {ChangePasswordRequestModel} [changePasswordRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthChangePasswordPost(
    changePasswordRequestModel?: ChangePasswordRequestModel,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthChangePasswordPost(changePasswordRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConfirmEmailAddressRequestModel} [confirmEmailAddressRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthConfirmEmailPost(
    confirmEmailAddressRequestModel?: ConfirmEmailAddressRequestModel,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthConfirmEmailPost(confirmEmailAddressRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RestorePasswordRequestModel} [restorePasswordRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthForgotPasswordPost(
    restorePasswordRequestModel?: RestorePasswordRequestModel,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthForgotPasswordPost(restorePasswordRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ResetPasswordRequestModel} [resetPasswordRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthForgotPasswordRequestPost(
    resetPasswordRequestModel?: ResetPasswordRequestModel,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthForgotPasswordRequestPost(resetPasswordRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginModel} [loginModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthLoginPost(
    loginModel?: LoginModel,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthLoginPost(loginModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthRefreshTokenGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .apiV1AuthRefreshTokenGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RefreshTokenRequestModel} [refreshTokenRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthRefreshTokenPost(
    refreshTokenRequestModel?: RefreshTokenRequestModel,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthRefreshTokenPost(refreshTokenRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public apiV1AuthSendAccountConfirmationPost(
    email?: string,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .apiV1AuthSendAccountConfirmationPost(email, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesDeviceIdGet: async (
      deviceId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists("apiAdminDevicesDeviceIdGet", "deviceId", deviceId);
      const localVarPath = `/api/admin/devices/{deviceId}`.replace(
        `{${"deviceId"}}`,
        encodeURIComponent(String(deviceId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} hwid
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesHardwareIdHwidGroupIdGet: async (
      hwid: string,
      groupId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'hwid' is not null or undefined
      assertParamExists(
        "apiAdminDevicesHardwareIdHwidGroupIdGet",
        "hwid",
        hwid
      );
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists(
        "apiAdminDevicesHardwareIdHwidGroupIdGet",
        "groupId",
        groupId
      );
      const localVarPath = `/api/admin/devices/hardwareId/{hwid}/{groupId}`
        .replace(`{${"hwid"}}`, encodeURIComponent(String(hwid)))
        .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesListPost: async (
      pagedCollectionFilter?: PagedCollectionFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/devices/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagedCollectionFilter,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesLookupsGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/devices/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeviceWithNodesModel} [deviceWithNodesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesPost: async (
      deviceWithNodesModel?: DeviceWithNodesModel,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/devices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deviceWithNodesModel,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesSimplelistPost: async (
      pagedCollectionFilter?: PagedCollectionFilter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/devices/simplelist`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagedCollectionFilter,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminDevicesDeviceIdGet(
      deviceId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceWithNodesModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminDevicesDeviceIdGet(
          deviceId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} hwid
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminDevicesHardwareIdHwidGroupIdGet(
      hwid: string,
      groupId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminDevicesHardwareIdHwidGroupIdGet(
          hwid,
          groupId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminDevicesListPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceModelPagedCollectionModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminDevicesListPost(
          pagedCollectionFilter,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminDevicesLookupsGet(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceLookupList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminDevicesLookupsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {DeviceWithNodesModel} [deviceWithNodesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminDevicesPost(
      deviceWithNodesModel?: DeviceWithNodesModel,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceWithNodesModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminDevicesPost(
          deviceWithNodesModel,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAdminDevicesSimplelistPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SimpleDeviceModelPagedCollectionModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAdminDevicesSimplelistPost(
          pagedCollectionFilter,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeviceApiFp(configuration);
  return {
    /**
     *
     * @param {number} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesDeviceIdGet(
      deviceId: number,
      options?: any
    ): AxiosPromise<DeviceWithNodesModel> {
      return localVarFp
        .apiAdminDevicesDeviceIdGet(deviceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} hwid
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesHardwareIdHwidGroupIdGet(
      hwid: string,
      groupId: string,
      options?: any
    ): AxiosPromise<DeviceModel> {
      return localVarFp
        .apiAdminDevicesHardwareIdHwidGroupIdGet(hwid, groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesListPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: any
    ): AxiosPromise<DeviceModelPagedCollectionModel> {
      return localVarFp
        .apiAdminDevicesListPost(pagedCollectionFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesLookupsGet(options?: any): AxiosPromise<DeviceLookupList> {
      return localVarFp
        .apiAdminDevicesLookupsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeviceWithNodesModel} [deviceWithNodesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesPost(
      deviceWithNodesModel?: DeviceWithNodesModel,
      options?: any
    ): AxiosPromise<DeviceWithNodesModel> {
      return localVarFp
        .apiAdminDevicesPost(deviceWithNodesModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PagedCollectionFilter} [pagedCollectionFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAdminDevicesSimplelistPost(
      pagedCollectionFilter?: PagedCollectionFilter,
      options?: any
    ): AxiosPromise<SimpleDeviceModelPagedCollectionModel> {
      return localVarFp
        .apiAdminDevicesSimplelistPost(pagedCollectionFilter, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeviceApi - interface
 * @export
 * @interface DeviceApi
 */
export interface DeviceApiInterface {
  /**
   *
   * @param {number} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApiInterface
   */
  apiAdminDevicesDeviceIdGet(
    deviceId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceWithNodesModel>;

  /**
   *
   * @param {string} hwid
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApiInterface
   */
  apiAdminDevicesHardwareIdHwidGroupIdGet(
    hwid: string,
    groupId: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceModel>;

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApiInterface
   */
  apiAdminDevicesListPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceModelPagedCollectionModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApiInterface
   */
  apiAdminDevicesLookupsGet(
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceLookupList>;

  /**
   *
   * @param {DeviceWithNodesModel} [deviceWithNodesModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApiInterface
   */
  apiAdminDevicesPost(
    deviceWithNodesModel?: DeviceWithNodesModel,
    options?: AxiosRequestConfig
  ): AxiosPromise<DeviceWithNodesModel>;

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApiInterface
   */
  apiAdminDevicesSimplelistPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ): AxiosPromise<SimpleDeviceModelPagedCollectionModel>;
}

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI implements DeviceApiInterface {
  /**
   *
   * @param {number} deviceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApi
   */
  public apiAdminDevicesDeviceIdGet(
    deviceId: number,
    options?: AxiosRequestConfig
  ) {
    return DeviceApiFp(this.configuration)
      .apiAdminDevicesDeviceIdGet(deviceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} hwid
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApi
   */
  public apiAdminDevicesHardwareIdHwidGroupIdGet(
    hwid: string,
    groupId: string,
    options?: AxiosRequestConfig
  ) {
    return DeviceApiFp(this.configuration)
      .apiAdminDevicesHardwareIdHwidGroupIdGet(hwid, groupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApi
   */
  public apiAdminDevicesListPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ) {
    return DeviceApiFp(this.configuration)
      .apiAdminDevicesListPost(pagedCollectionFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApi
   */
  public apiAdminDevicesLookupsGet(options?: AxiosRequestConfig) {
    return DeviceApiFp(this.configuration)
      .apiAdminDevicesLookupsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeviceWithNodesModel} [deviceWithNodesModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApi
   */
  public apiAdminDevicesPost(
    deviceWithNodesModel?: DeviceWithNodesModel,
    options?: AxiosRequestConfig
  ) {
    return DeviceApiFp(this.configuration)
      .apiAdminDevicesPost(deviceWithNodesModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PagedCollectionFilter} [pagedCollectionFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceApi
   */
  public apiAdminDevicesSimplelistPost(
    pagedCollectionFilter?: PagedCollectionFilter,
    options?: AxiosRequestConfig
  ) {
    return DeviceApiFp(this.configuration)
      .apiAdminDevicesSimplelistPost(pagedCollectionFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GetRoofDataApi - axios parameter creator
 * @export
 */
export const GetRoofDataApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} adpid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiGetRoofDataAdpAdpidGet: async (
      adpid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'adpid' is not null or undefined
      assertParamExists("apiGetRoofDataAdpAdpidGet", "adpid", adpid);
      const localVarPath = `/api/GetRoofData/adp/{adpid}`.replace(
        `{${"adpid"}}`,
        encodeURIComponent(String(adpid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetRoofDataApi - functional programming interface
 * @export
 */
export const GetRoofDataApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GetRoofDataApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} adpid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiGetRoofDataAdpAdpidGet(
      adpid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiGetRoofDataAdpAdpidGet(
          adpid,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * GetRoofDataApi - factory interface
 * @export
 */
export const GetRoofDataApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GetRoofDataApiFp(configuration);
  return {
    /**
     *
     * @param {string} adpid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiGetRoofDataAdpAdpidGet(
      adpid: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiGetRoofDataAdpAdpidGet(adpid, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetRoofDataApi - interface
 * @export
 * @interface GetRoofDataApi
 */
export interface GetRoofDataApiInterface {
  /**
   *
   * @param {string} adpid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetRoofDataApiInterface
   */
  apiGetRoofDataAdpAdpidGet(
    adpid: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * GetRoofDataApi - object-oriented interface
 * @export
 * @class GetRoofDataApi
 * @extends {BaseAPI}
 */
export class GetRoofDataApi extends BaseAPI implements GetRoofDataApiInterface {
  /**
   *
   * @param {string} adpid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetRoofDataApi
   */
  public apiGetRoofDataAdpAdpidGet(
    adpid: string,
    options?: AxiosRequestConfig
  ) {
    return GetRoofDataApiFp(this.configuration)
      .apiGetRoofDataAdpAdpidGet(adpid, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
