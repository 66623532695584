










































































































// eslint-disable-next-line object-curly-newline
import {
  mdiKeyOutline,
  mdiLockOpenOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
} from "@mdi/js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DemoForm extends Vue {
  @Prop({ default: false }) isCPasswordVisible: boolean;
  @Prop({ default: false }) isNewPasswordVisible: boolean;
  @Prop({ default: false }) isCurrentPasswordVisible: boolean;

  @Prop({ default: "87654321" }) cPassword: string;
  @Prop({ default: "87654321" }) newPassword: string;
  @Prop({ default: "12345678" }) currentPassword: string;
  icons = {
    mdiKeyOutline,
    mdiLockOpenOutline,
    mdiEyeOffOutline,
    mdiEyeOutline,
  };
}
