import { Actions } from "./EditorInterfaces";


export const contextMenuDefinition = [
    {
        title: "Create",
        action: Actions.newPanel,
        selection: false,
        empty: true,
    },
    { title: "Delete", action: Actions.delete, selection: true, empty: false },
    {
        title: "To Circle",
        action: Actions.toCircle,
        type: "rect",
        selection: true,
        empty: false,
    },
    {
        title: "To Rectangle",
        action: Actions.toRectangle,
        type: "circle",
        selection: true,
        empty: false,
    },
    { title: "Lock", action: Actions.lock, selection: true, empty: false },
    { title: "Unlock", action: Actions.unlock, selection: true, empty: false },
    {
        title: "Set details",
        action: Actions.noAction,
        selection: true,
        empty: false,
    },
];