






































































































import Vue from "vue";
import Component from "vue-class-component";
import * as icons from "@mdi/js";
import LeftMenu from "./components/leftMenu/LeftMenu.vue";
import AppBarUserMenu from "./components/AppBarUserMenu.vue";

@Component({
  components: {
    LeftMenu: () => Promise.resolve(LeftMenu),
    AppBarUserMenu: () => Promise.resolve(AppBarUserMenu),
  },
})
export default class RootView extends Vue {
  isDrawerOpen = true;

  icons = icons;
  messages: Array<string> = [];
  alerts: Array<string> = [];
  get barHeight() {
    return this.$vuetify.breakpoint.xs ? 64 : 120;
  }
  async mounted() {}
}
