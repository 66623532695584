
export class WetMapFlags {
    debug: boolean;
    showMask: boolean;
    showHeatmap: boolean;
    showHeaders: boolean;
    showValues: boolean;
    showPanelColors: boolean;
    /**
     *
     */
    constructor() {
        this.debug = false;
        this.showMask = true;
        this.showHeatmap = true;
        this.showHeaders = true;
        this.showValues = false;
        this.showPanelColors = false;
    }
}
