import Vue, { VueConstructor } from "vue";
import InfoTag from './infotag.vue';
import Loading from './loading.vue';

export const coreComponent = {
    install(Vue: VueConstructor<Vue>, options: any) {
        Vue.component("InfoTag", InfoTag);
        Vue.component("Loading", Loading);
    }
};
export { InfoTag, Loading };
