

































































































































import {
  DeviceLookupList,
  DeviceModelPagedCollectionModel,
  DeviceWithNodesModel,
  FieldFilterType,
  Lookup,
  PagedCollectionFilter,
} from "@/core/webapi";
import { DeviceService } from "@/services/devices";
import { Component, Vue } from "vue-property-decorator";
import * as icons from "@mdi/js";

@Component({
  components: {},
})
export default class DeviceList extends Vue {
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortOperator: "ascending",
    filters: [],
    includeDeleted: true,
  };
  statusSelect = 1;
  icons = {
    iconEdit: icons.mdiFileEdit,
  };
  form = {
    name: "",
    description: "",
    hardwareId: "",
    maskFile: {},
    mask: "",
    photoFile: {},
    photo: "",
    threshold: 10,
    status: "Active",
    statusId: 1,
  };
  dialog = false;
  loading = false;
  rules = [
    (value: any) =>
      !value || value.size < 2000000 || "Image size should be less than 2 MB!",
  ];
  model = {} as DeviceModelPagedCollectionModel;
  lookups = new DeviceLookupList();
  photoChange(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.form.photo = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }
  maskChange(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.form.mask = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }
  async updateStatusFilter() {
    this.filter.page = 1;
    if (this.statusSelect > 0) {
      this.filter.filters = [
        {
          fieldName: "statusId",
          filterType: FieldFilterType.NUMBER_0,
          value: this.statusSelect.toString(),
        },
      ];
    } else {
      this.filter.filters = (this.filter.filters || []).filter(
        (x) => x.fieldName != "statusId",
      );
    }
    await this.loadDevices();
  }
  async created() {}
  async saveDevice() {
    this.loading = true;
    await DeviceService.Update(this.form as any);
    this.dialog = false;
    this.loading = false;
  }
  async mounted() {
    this.loading = true;
    // create a local copy so I can add a null value on top
    const fullLookup = await DeviceService.getLookups();
    this.lookups = {
      ...fullLookup, // this is in case we start adding more lookups
      statusList: [...(fullLookup.statusList ?? [])], // this is to copy locally the array of statuses
    };
    this.lookups.statusList?.unshift({
      key: -1,
      name: "All",
    });
    await this.updateStatusFilter();
  }
  async loadDevices() {
    this.loading = true;
    this.model = await DeviceService.GetList({
      ...this.filter,
      page: Number(this.filter.page) - 1,
    });
    this.loading = false;
  }
  async next() {
    await this.loadDevices();
  }
  async prev() {
    await this.loadDevices();
  }
  async input(number: number) {
    this.filter.page = number;
    await this.loadDevices();
  }
  registered(evt: DeviceWithNodesModel) {
    this.dialog = false;
    this.model.items?.push(evt);
  }
}
