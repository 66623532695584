import { AppModule, AppRouteConfig } from "@/core/appTypes";

import AlertSearch from './AlertSearch.vue';

import * as icons from "@mdi/js";
export const searchModule: AppModule = {
    routes: [
        {
            path: '/search',
            name: 'Search',
            component: AlertSearch,
            meta: {
                allowAnonymous: false,
            },
            visible: true,
            icon: icons.mdiTagSearch,
            label: 'Node Inspection',
            order: 40
        },
    ]
};