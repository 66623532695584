import Vue from "vue";
import { AuthTokenService } from "./auth-token.service";
import { AppConfig } from "@/configuration";
import { AxiosFactory } from "../core";
import { AuthApiFactory, AuthApiFp, Configuration, ResetPasswordRequestModel, Token, TokenWrapper } from "@/core/webapi";

export class RestorePasswordRequestModel {
    email: string;
    newPassword: string;
    confirmPassword: string;
    token: string;
}


export class ChangePasswordModel {
    email: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}
export class ConfirmEmailAddressModel {
    token: any;
    email: string;
}
const api = AuthApiFactory(new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create());
export const AuthService = new Vue({
    methods: {
        setToken(tokenResponse: any) {
            this.$auth.setToken(tokenResponse);
            AuthTokenService.setRefreshToken(tokenResponse.refresh_token || "");
            this.$emit("USER_UPDATED");
        },
        async login(model: any) {
            try {
                const req = await api.apiV1AuthLoginPost(model);
                const res = await req;
                return res.data;
            } catch (error) {
                return new TokenWrapper({});
            }
        },
        async refreshToken() {
            const currentRefreshToken = AuthTokenService.getRefreshToken();
            if (!currentRefreshToken) {
                return null;
            }

            const response = await (await api.apiV1AuthRefreshTokenPost({
                refreshToken: currentRefreshToken,
            })).data;

            if (response && response.token) {
                this.setToken(response.token);
            }
            return response;
        },
        async confirmEmailAddress(code: string, email: string) {
            return await (await api.apiV1AuthConfirmEmailPost({ token: code, email })).data;
        },
        async restorePassword(model: RestorePasswordRequestModel) {
            return await (await api.apiV1AuthForgotPasswordPost(model)).data;
        },
        async resetPassword(model: ResetPasswordRequestModel) {
            return await (await api.apiV1AuthForgotPasswordRequestPost(model)).data;
        },
        async changePassword(model: ChangePasswordModel) {
            return await (await api.apiV1AuthChangePasswordPost(model)).data;
        },
    }
});