import { AppModule, AppRouteConfig } from "@/core/appTypes";
import Login from './Login.vue';
import Logout from './Logout.vue';
import ConfirmEmail from './ConfirmEmail.vue';
import ResetPassword from './ResetPassword.vue';
import ResetPasswordForm from './ResetPasswordForm.vue';

import * as icons from "@mdi/js";
export const authModule: AppModule = {
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                layout: 'blank',
                allowAnonymous: true,
            },
            visible: false,
            icon: '',
            label: '',
        },

        {
            path: "/emailConfirmation/:emailConfirmationToken/:userEmailAddress",
            name: "confirmEmail",
            meta: { layout: "empty", allowAnonymous: true },
            component: ConfirmEmail,
            visible: false,
            icon: '',
            label: ''
        },
        {
            path: '/resetpassword',
            name: 'resetPasswordRequest',
            component: ResetPassword,
            meta: {
                layout: 'blank',
                allowAnonymous: true,
            },
            visible: false,
            icon: '',
            label: ''
        },
        {
            path: "/resetPassword/form/:emailConfirmationToken/:userEmailAddress",
            name: "resetPasswordForm",
            meta: { layout: "empty", allowAnonymous: true },
            component: ResetPasswordForm,
            visible: false,
            icon: '',
            label: ''
        },
        {
            path: "/resetPassword/form",
            name: "resetPasswordFormQueryString",
            meta: { layout: "empty", allowAnonymous: true },
            props: route => ({ token: route.query.token, email: route.query.token }),
            component: ResetPasswordForm,
            visible: false,
            icon: '',
            label: ''
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: {
                layout: 'blank', allowAnonymous: true
            },
            visible: false,
            icon: icons.mdiLogout,
            label: 'Logout',
            order: 999,
        },

    ]
};

