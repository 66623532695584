import Vue from 'vue';
import VueRouter, { RouteConfig, RouteRecord } from 'vue-router';

Vue.use(VueRouter);

import { AuthService } from "@/services";
import qs from "qs";
import { AppRouteConfig } from "@/core/appTypes";
import { accountModule, authModule, searchModule, deviceModule, viewerModule, alarmsModule } from "@/modules";
import AccountSettings from '../views/pages/account-settings/AccountSettings.vue';
import * as icons from "@mdi/js";
import { editorModule } from "@/modules/editor/editor.module";

export const routes: Array<AppRouteConfig> = [
    ...authModule.routes,
    ...deviceModule.routes,
    ...searchModule.routes,
    ...viewerModule.routes,
    ...editorModule.routes,
    ...alarmsModule.routes,
    ...accountModule.routes,
    {
        path: '/account/settings',
        name: 'accountSettings',
        component: AccountSettings,
        visible: false,
        icon: icons.mdiTable,
        label: 'AccountSettings',
    },

];

export const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    stringifyQuery: query => {
        const result = qs.stringify(query, { format: 'RFC1738' });
        return result ? ('?' + result) : '';
    },
    routes
});


router.beforeEach(async (to, from, next) => {
    const userStatus = {
        allowAnonymous: to.matched.some((record: RouteRecord) => record.meta.allowAnonymous),
        isAuthenticated: router.app.$auth.isAuthenticated(),
    };
    if (!userStatus.allowAnonymous && !userStatus.isAuthenticated) {
        try {
            const response = await AuthService.refreshToken();
            if (response && response.token) {
                AuthService.setToken(response.token);
                next();
                return;
            }
        } catch (error) {
            console.error(error);
        }
        next({
            path: "/",
            query: {
                redirectTo: to.path,
            },
        });
        return;

    }
    next();
});
