













































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AddNodesButton extends Vue {
  addNodesDialog = false;
  width = 70;
  height = 50;
  border = 5;
  startRow = 0;
  startColumn = 0;
  endRow = 10;
  endColumn = 10;

  async created() {}
  addNodes(
    startRow: number,
    startColumn: number,
    endRow: number,
    endColumn: number,
    width: number,
    height: number,
    border: number,
  ) {
    this.addNodesDialog = false;
    this.$emit("addNodes", {
      startRow: Number(startRow),
      startColumn: Number(startColumn),
      endRow: Number(endRow),
      endColumn: Number(endColumn),
      width: Number(width),
      height: Number(height),
      border: Number(border),
    });
  }
  async mounted() {}
}
