


























import { Component, Prop, Vue } from "vue-property-decorator";

class RowColVertex {
  row: number;
  col: number;
}

@Component({
  components: {},
})
export default class AddNodeDialog extends Vue {
  @Prop({ default: false }) showDialog: boolean;
  async created() {}
  rowColDialogOk(model?: RowColVertex) {
    this.$emit("dialogOk", model);
  }

  async mounted() {}
}
