
































































































import OnlineDevices, {
  OnlineDeviceModel,
} from "@/components/OnlineDevices.vue";
import { WetMapFlags } from "@/components/RendererModels";
import SquaredMap, { ImageDefinition } from "@/components/SquaredMap.vue";
import { DeviceService, IPanelCenter, TileOptions } from "@/services/devices";
import Vue from "vue";
import Component from "vue-class-component";

class ColorDefinition {
  color: string;
  value: number;
}
@Component({
  components: {
    OnlineDevices: () => Promise.resolve(OnlineDevices),
    SquaredMap: () => Promise.resolve(SquaredMap),
  },
})
export default class RealtimeGridWetmap extends Vue {
  loading = false;
  max: number = 150;
  backgroundPhoto = "";
  zoom: number = 1;
  availableWidth = 1000;

  radius: number = 5;
  blur: number = 2;
  board = "1";
  items = ["1", "2", "3", "4", "5"];
  newColor: string = "";
  offsetX: number = 0;
  offsetY: number = 0;
  hoverValue: number = 0;
  hoverLocation: string = "";
  centers: IPanelCenter[] = [];
  tileOptions: TileOptions = {
    width: 10,
    height: 10,
    border: 2,
  } as TileOptions;
  anotherone: WetMapFlags = {
    debug: true,
    showMask: true,
    showHeatmap: true,
    showHeaders: true,
    showValues: true,
    showPanelColors: true,
  };
  wetmapflags: WetMapFlags = {
    options: "by adding a string, I wonder what happens",
    wi: 20,
    debug: true,
    showMask: true,
    showHeatmap: true,
    showHeaders: true,
    showValues: true,
    showPanelColors: true,
  } as WetMapFlags;
  colors: string[] = CSS_COLOR_NAMES;
  backgroundOptions: any = {};
  get availableColors() {
    return this.colors.filter((x) => this.gradientOptions[x] === undefined);
  }
  gradientModel: ColorDefinition[] = [
    { color: "White", value: 10 },
    { color: "Yellow", value: 50 },
    { color: "Red", value: 200 },
  ];

  masks: ImageDefinition[] = [
    {
      src: require("@/assets/buildings/model/roof1-negative-mask.png"),
      options: { row: 4, col: 0, toRow: 7, toCol: 7 },
    },
    {
      src: require("@/assets/buildings/model/roof2-negative-mask.png"),
      options: { row: 4, col: 1, toRow: 5, toCol: 3 },
    },
    {
      src: require("@/assets/buildings/model/roof3-negative-mask.png"),
      options: { row: 0, col: 5, toRow: 2, toCol: 7 },
    },
    {
      src: require("@/assets/buildings/model/roof4-negative-mask.png"),
      options: { row: 1, col: 3, toRow: 2, toCol: 3 },
    },
  ];
  getCurrentSettings() {
    return JSON.stringify({
      tileOptions: this.tileOptions,
      gradientModel: this.gradientModel,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      radius: this.radius,
      blur: this.blur,
      wetmapflags: this.wetmapflags,
      max: this.max,
    });
  }
  panelHover(item: any) {
    if (item && item.value > -1) {
      this.hoverValue = item.value;
      this.hoverLocation = item.location;
    }
  }
  panelOut() {
    this.hoverLocation = "";
    this.hoverValue = 0;
  }
  getDimensions() {
    this.availableWidth =
      document.querySelector<HTMLElement>(".mapcontainer")?.offsetWidth || 1000;
  }
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    this.load();
  }
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  }
  deleteColor(color: string) {
    this.gradientModel = this.gradientModel.filter((x) => x.color != color);
  }
  get lastPacketFromSelectedDevice() {
    if (!this.selectedDevice) return [];
    if (!this.selectedDevice.lastPacket) return [];
    if (!this.selectedDevice.lastPacket.panels) return [];
    return this.selectedDevice.lastPacket.panels.map((x: any) => x.value); // array of values, in the order we received them.
  }
  selectedDevice: any = "";
  async onSelectDevice(d: OnlineDeviceModel) {
    this.loading = true;
    this.selectedDevice = d;

    const hwId = this.selectedDevice.deviceName;
    const groupId = this.selectedDevice.groupId;
    const device = await DeviceService.GetDetailFromHardwareId(hwId, groupId);

    if (device && device.mapSettings) {
      this.centers = device.centers
        ? (JSON.parse(device.centers) as IPanelCenter[])
        : [];

      this.backgroundPhoto = device.photo as string;

      const parsed = JSON.parse(device.mapSettings);
      if (parsed.gradientModel && parsed.gradientModel.length > 0) {
        this.gradientModel = parsed.gradientModel;
      }
      this.offsetX = parsed.offsetX;
      this.offsetY = parsed.offsetY;
      this.tileOptions = parsed.tileOptions || this.tileOptions;
      this.backgroundOptions =
        parsed.backgroundOptions || this.backgroundOptions;
      this.wetmapflags = parsed.wetmapflags || this.wetmapflags;
    }
    this.loading = false;
  }
  async load() {
    this.loading = true;
    if (this.selectedDevice) {
      this.onSelectDevice(this.selectedDevice);
      return;
    }
    let settings = localStorage.getItem("settings");
    if (!settings) {
      settings = this.getCurrentSettings();
      localStorage.setItem("settings", settings);
    } else {
      const ob = JSON.parse(settings);
      this.tileOptions = ob.tileOptions;
      this.gradientModel = ob.gradientModel;
      this.offsetX = ob.offsetX;
      this.offsetY = ob.offsetY;
      this.radius = ob.radius;
      this.blur = ob.blur;
      this.wetmapflags = ob.wetmapflags || this.wetmapflags;
      this.max = ob.max;
    }
    this.loading = false;
  }

  transform(value: number) {
    return (1 / this.max) * value;
  }
  sort() {
    this.gradientModel.sort((x, b) => x.value - b.value);
  }
  addColor() {
    if (!this.newColor) return;
    if (!this.gradientModel.find((x) => x.color == this.newColor)) {
      this.gradientModel.push({ color: this.newColor, value: 100 });
    }
    this.sort();
  }
  get gradientOptions() {
    var ob: any = {};
    this.gradientModel.forEach((c) => {
      ob[c.color] = this.transform(c.value);
    });
    return ob;
  }
}
const CSS_COLOR_NAMES = [
  "Black",
  "Blue",
  "BlueViolet",
  "Grey",
  "Green",
  "Orange",
  "OrangeRed",
  "Purple",
  "Red",
  "Violet",
  "White",
  "Yellow",
];
