
import { AppConfig } from "@/configuration";
import { AggregationApiFactory, AggregationSegment, Configuration, DeviceTableRowModel } from "@/core/webapi";
import axios from "axios";
import moment, { Moment } from "moment";
import Vue from "vue";
import { AxiosFactory } from "../core";

const api = AggregationApiFactory(new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create());
export class AggregateResultModel {
    deviceId: string;
    timestamp: moment.Moment;
    data: Array<number>;
}

export const dataService = new Vue({
    methods: {
        async getData(adpId: number): Promise<DataPointsModel> {
            return (await AxiosFactory.create().get(`${AppConfig.APIUrl}/api/getroofdata/adp/${adpId}`)).data as DataPointsModel;
        },
        async queryAlerts(model: AggregationSegment) {
            return await (await (api.apiDataAggregationQueryAlertsPost(model))).data as Array<DeviceTableRowModel>;
        },
        async queryAggregatedAlerts(model: AggregationSegment) {
            return await (await (api.apiDataAggregationQueryAggregatesPost(model))).data as Array<DeviceTableRowModel>;
        },

        parseResults(model: Array<DeviceTableRowModel>) {

            return model.filter(x => x.wetmapArray != null).map(x => ({
                deviceId: x.deviceId,
                timestamp: moment.utc(x.timestamp),
                data: this.toMatrix(JSON.parse(x.wetmapArray as string) as number[], 46)
            }));
        },

        async queryAggregateAlertsArray(model: AggregationSegment): Promise<AggregateResultModel[]> {
            const results = await this.queryAggregatedAlerts(model);
            return results.filter(x => x.wetmapArray != null).map(x => ({
                deviceId: x.deviceId as string,
                timestamp: moment.utc(x.timestamp),
                data: JSON.parse(x.wetmapArray as string) as number[]
            }));
        },
        async queryAlertsParsed(model: AggregationSegment) {
            const results = await this.queryAlerts(model);

            return this.parseResults(results);
        },
        toMatrix(arr: Array<any>, width: number) {
            return arr.reduce((rows, key, index) => (index % width == 0 ? rows.push([key])
                : rows[rows.length - 1].push(key)) && rows, []);
        },
    }
});



export class DataPointsModel {
    public name: string;
    public points: Array<Array<number>>;
}