

















































































































































































































































































































import { WetMapFlags } from "@/components/RendererModels";
import { DeviceWithNodesModel, Lookup, UserRow } from "@/core/webapi";
import { AccountService } from "@/services";
import { DeviceService, MapSettings, TileOptions } from "@/services/devices";
import { Component, Vue, Watch } from "vue-property-decorator";
import Editor from "../editor/Editor.vue";

@Component({
  components: {
    Editor: () => Promise.resolve(Editor),
  },
})
export default class DeviceDetail extends Vue {
  model = new DeviceWithNodesModel();

  users: UserRow[] = [];
  loading = false;
  editorDialog = false;
  statusList: Lookup[] = [];
  mapSettings: MapSettings = new MapSettings();
  colors: string[] = CSS_COLOR_NAMES;
  newColor: string = "";
  dirty: boolean = false;
  @Watch("model", { deep: true }) handler() {
    this.dirty = true;
  }
  @Watch("mapSettings", { deep: true }) handler2() {
    this.dirty = true;
  }
  get userDeviceRecords() {
    return this.users.map((x) => ({
      userName: x.userName,
      userId: x.userId,
    }));
  }

  get availableColors() {
    if (!this.mapSettings.gradientModel) return this.colors;
    return this.colors.filter(
      (x) => this.mapSettings.gradientModel.findIndex((c) => c.color === x) < 0,
    );
  }

  groupBy(key: string, v: any[]) {
    return v.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  get planSettingsDetails() {
    if (!this.model.planSettings) return {};
    var o = JSON.parse(this.model.planSettings as any)
      .objects.filter((x: any) => x.data)
      .filter((x: any) => x.data.panel)
      .map((x: any) => x.data);
    return {
      panels: o.length,
      row: Object.keys(this.groupBy("row", o)),
      col: Object.keys(this.groupBy("col", o)),
    };
  }
  closeEditor(editorResult: any) {
    this.editorDialog = false;
    if (!editorResult) {
      return;
    }
    const image = editorResult.background;
    const plan = editorResult.data;
    const imageOptions = editorResult.options;

    if (image) this.model.photo = image;
    this.dirty = true;

    this.mapSettings.backgroundOptions = imageOptions;
    this.model.planSettings = plan;
    this.model.centers = JSON.stringify(editorResult.centers);
  }

  sort() {
    this.mapSettings.gradientModel.sort((x: any, b: any) => x.value - b.value);
  }
  addColorDialog: boolean = false;
  addColor() {
    this.addColorDialog = false;
    if (!this.newColor) return;
    if (
      !this.mapSettings.gradientModel.find((x: any) => x.color == this.newColor)
    ) {
      this.mapSettings.gradientModel.push({ color: this.newColor, value: 100 });
    }
    this.sort();
  }
  deleteColor(color: string) {
    this.mapSettings.gradientModel = this.mapSettings.gradientModel.filter(
      (x) => x.color != color,
    );
  }

  async created() {}

  customFilter(item: any, queryText: string, itemText: string) {
    const textOne = item.userName.toLowerCase();
    const searchText = queryText.toLowerCase();
    return textOne.indexOf(searchText) > -1;
  }
  async mounted() {
    this.loading = true;
    this.statusList =
      (await (await DeviceService.getLookups()).statusList) ?? [];
    var id = Number(this.$route.params.id);
    if (id) {
      this.model = await DeviceService.GetDetail(id);

      if (!this.model.mapSettings) {
        this.model.mapSettings = "{}";
      }
      this.mapSettings = JSON.parse(this.model.mapSettings as string);
      if (!this.mapSettings.wetmapflags) {
        this.mapSettings.wetmapflags = new WetMapFlags();
      }
      if (!this.mapSettings.max) {
        this.mapSettings.max = 200;
      }
    } else {
      this.model = new DeviceWithNodesModel();
      this.model.mask = "";
      this.model.photo = "";
      this.model.mapSettings = "";
      this.model.planSettings = "";
      this.model.statusId = 1;
    }
    this.users = (await (
      await AccountService.getUsers({
        filters: [],
        includeDeleted: false,
        search: "",
        sortBy: "",
        sortOperator: "",
        page: 0,
        pageSize: 100,
      })
    ).items) as UserRow[];
    this.loading = false;
    this.$nextTick(() => {
      this.dirty = false;
    });
  }
  async update() {
    this.loading = true;
    this.sort();
    this.model.mapSettings = JSON.stringify(this.mapSettings);
    const response = await DeviceService.Update(this.model);
    this.model = await DeviceService.GetDetail(
      response.data.deviceId as number,
    );

    this.$emit("saved", this.model);
    this.$nextTick(() => {
      this.loading = false;
      this.dirty = false;
    });
  }
}
const CSS_COLOR_NAMES = [
  "Transparent",
  "Black",
  "Blue",
  "BlueViolet",
  "Grey",
  "Green",
  "Orange",
  "OrangeRed",
  "Purple",
  "Red",
  "Violet",
  "White",
  "Yellow",
];
