



























































import {
  PagedCollectionFilter,
  UserRowPagedCollectionModel,
} from "@/core/webapi";
import { AccountService } from "@/services";
import { Component, Vue } from "vue-property-decorator";
import * as icons from "@mdi/js";

@Component({
  components: {},
})
export default class AccountList extends Vue {
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 10,
    search: "",
    sortBy: "",
    sortOperator: "ascending",
    includeDeleted: true,
    filters: [],
  };
  icons = {
    iconEdit: icons.mdiFileEdit,
  };
  loading = false;
  model = {} as UserRowPagedCollectionModel;
  async created() {}
  async loadUsers() {
    this.loading = true;
    this.model = await AccountService.getUsers({
      ...this.filter,
      page: Number(this.filter.page) - 1,
    });
    this.loading = false;
  }

  async mounted() {
    await this.loadUsers();
  }

  async next() {
    await this.loadUsers();
  }
  async prev() {
    await this.loadUsers();
  }
  async input(number: number) {
    this.filter.page = number;
    await this.loadUsers();
  }
}
