
import { Vue } from "vue-property-decorator";

export default class AppVue extends Vue {
  currentTitle: string = "";

  get currentEmailAddress() {
    const o = this.$auth.getPayload();
    if (!o) return "";
    return o[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
    ];
  }
  get currentFirstRole() {
    const o = this.$auth.getPayload();
    if (!o) return "";
    return o["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  }
  get currentName() {
    const o = this.$auth.getPayload();
    if (!o) return "";
    return o["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get isDevEnv() {
    return this.environment === "Dev";
  }

  get isTestEnv() {
    return this.environment === "Test";
  }

  get isProdEnv() {
    return this.environment === "Production";
  }
}
