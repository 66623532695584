













































































































import { Component } from "vue-property-decorator";
import { AccountService } from "@/services";
import AppVue from "@/AppVue.vue";
import { AccountModel, AdminLookupList } from "@/core/webapi";

@Component({
  components: {},
})
export default class AccountDetail extends AppVue {
  loading = false;
  model: AccountModel = new AccountModel();
  lookups: AdminLookupList = new AdminLookupList();
  async created() {}

  async mounted() {
    this.loading = true;
    const id = Number(this.$route.params.id);
    if (id) {
      this.lookups = await AccountService.getLookups();
      this.model = await AccountService.getUserDetail(id);
    }
    this.loading = false;
  }
  update() {}
}
