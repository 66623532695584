import Vue from "vue";
import * as moment from "moment";

// If token more than 180 minutes old, attempt token refresh
const REFRESH_TOKEN_KEY = "vue_onedrop.refresh_token";
const tokenValidFor = 3 * 60;
const refreshThreshold = tokenValidFor - 60;

export const AuthTokenService = new Vue({
    methods: {
        currentTokenValue(claim: string) {
            const pload = this.$auth.getPayload();
            if (!pload) {
                return "";
            }
            return pload[claim];
        },
        currentTokenUser() {
            const pload = this.$auth.getPayload();
            if (!pload) {
                return "";
            }
            return pload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        },
        setRefreshToken(token: string) {
            if (localStorage) {
                localStorage.setItem(REFRESH_TOKEN_KEY, token);
            } else if (sessionStorage) {
                sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
            }
        },

        clearRefreshToken() {
            if (localStorage) {
                localStorage.removeItem(REFRESH_TOKEN_KEY);
            }
            if (sessionStorage) {
                sessionStorage.removeItem(REFRESH_TOKEN_KEY);
            }
        },

        getRefreshToken() {
            if (localStorage) {
                return localStorage.getItem(REFRESH_TOKEN_KEY);
            }

            if (sessionStorage) {
                return sessionStorage.getItem(REFRESH_TOKEN_KEY);
            }
            return null;
        },
        getRemainingTokenDuration() {
            const payload = this.$auth.getPayload();
            if (!payload) {
                return moment.duration(0);
            }

            const expiryTime = moment.unix(payload.exp).utc();
            const now = moment.utc();
            const diff = moment.duration(expiryTime.diff(now));

            return diff;
        },
        getRemainingTokenSeconds() {
            return Number(
                this.getRemainingTokenDuration()
                    .asSeconds()
                    .toFixed(0),
            );
        },
        getRemainingTokenMinutes() {
            return this.getRemainingTokenDuration().asMinutes();
        },
        emitTokenRefreshIfNecessary() {
            const diff = this.getRemainingTokenMinutes();
            if (diff < refreshThreshold && diff > 0) {
                this.$emit("REFRESH_TOKEN");
            }
        },
    }
});