import { mdiUfo } from "@mdi/js";
import { getDataDetail } from "@microsoft/signalr/dist/esm/Utils";
import { fabric } from "fabric";
import { Actions, CursorMode, ImageSize } from "./EditorInterfaces";


export class WetmapEditor {

    // current unsaved state
    state: any = {};
    // past states
    undo: string[] = [];
    // reverted states
    redo: string[] = [];
    canvas: fabric.Canvas;
    isDown: boolean = false;
    object: fabric.Line; // the currently edited object. I think this should be a context element, but it's fine here for now.
    options: fabric.IObjectOptions = {
        stroke: "black",
        strokeWidth: 1,
        selectable: true,
        strokeUniform: true,
    };
    defaultSelectOption = {

    };
    cursorMode: CursorMode = CursorMode.Draw;

    gridWidth = 70;
    gridHeight = 50;

    isDragging = false;
    lastPosX: number;
    lastPosY: number;
    viewportTransform: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    resetViewportTransform: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    contextMenu: Function = () => { };

    backgroundImage: any = {};
    backgroundImageDataUrl: string = "";
    backgroundObject?: any;
    currentBackgroundOptions: fabric.IImageOptions;

    constructor(private readonly element: HTMLCanvasElement) {
        this.cursorMode = CursorMode.Draw;
        this.canvas = new fabric.Canvas(element, {
            selection: true,
            stopContextMenu: true,
            fireMiddleClick: true,
            fireRightClick: true,
            width: element.width - 100,
            height: element.height - 100,
        });
        // this.canvas.setDimensions({ width: 100, height: 200 });

        this.canvas.selectionColor = 'rgba(0,255,0,0.3)';
        this.canvas.selectionBorderColor = 'red';
        this.canvas.selectionLineWidth = 1;
        this.canvas.hoverCursor = 'pointer';

        this.setZoomControls();
        this.setDragControls();
        this.viewportTransform = this.canvas.viewportTransform as number[];
        this.resetViewportTransform = this.canvas.viewportTransform as number[];
    }

    public async LoadBackgroundDataUrl(photo: string, size: any = null): Promise<ImageSize> {
        this.backgroundImageDataUrl = photo;
        return await this.LoadBackground(this.backgroundImageDataUrl, size);
    }
    public async uploadBackgroundImage(e: File): Promise<ImageSize> {
        return new Promise((resolve, reject) => {
            if (!e) return reject();
            const fr = new FileReader();
            fr.onload = async (data) => {
                const img = await this.LoadBackgroundDataUrl(data.target?.result as string);
                return resolve(img);
            };
            fr.readAsDataURL(e);
        });
    }

    public redoAction() {
        if (this.redo.length == 0) return;
        this.undo.push(this.state);
        this.state = this.redo.pop();
        this.canvas.clear();
        this.canvas.loadFromJSON(this.state, () => {
            this.canvas.fire("object:loaded");
            this.canvas.renderAll();
        });
    }

    public undoAction() {
        if (this.undo.length == 0) return;
        this.redo.push(this.state);
        this.state = this.undo.pop();
        this.canvas.clear();
        this.canvas.loadFromJSON(this.state, () => {
            this.canvas.fire("object:loaded");
            this.canvas.renderAll();
        });
    }




    public setContextMenu(cb: Function) {
        this.contextMenu = cb;
    }

    public save() {
        // deselect everything so the temporary group doesn't interfere with the save process 
        // (x and y of the selected objects are relative to the origin of the selection group )
        this.canvas.discardActiveObject();
        this.canvas.backgroundImage = undefined;
        const j = this.canvas.toJSON(["data"]);

        // create a static copy of every object
        const centers = this.canvas
            .getObjects()
            .filter(x => x.data.panel)
            .map(panel => {
                return {
                    top: panel.top,
                    left: panel.left,
                    row: panel.data.row,
                    col: panel.data.col,
                    height: (panel.height as number) * (panel.scaleY as number),
                    width: (panel.width as number) * (panel.scaleX as number),
                    type: panel.data.type,
                    angle: panel.angle,
                };
            });
        return {
            data: JSON.stringify(j),
            background: this.backgroundImageDataUrl,
            options: this.currentBackgroundOptions,
            centers: centers,
        };
    }

    public async load(source: string) {

        return new Promise((resolve) => {
            this.canvas.loadFromJSON(source, () => {
                this.canvas.fire("object:loaded");
                return resolve("");
            });
        });
    }

    public renderAll() {
        this.canvas.renderAll();
    }
    public resetPan() {
        this.viewportTransform = [...this.resetViewportTransform];
        this.canvas.setViewportTransform(this.viewportTransform);
    }

    public resetZoom() {
        this.canvas.setZoom(1);
    }
    public executeAction(action: Actions, selection: fabric.Object[], currentContextPosition: any, options?: any) {
        switch (action) {
            case Actions.newPanel:
                {
                    if (!options) {
                        options = {
                            row: 0,
                            col: 0,
                        };
                    }
                    const top = currentContextPosition.cy;
                    const left = currentContextPosition.cx;

                    const rect = new fabric.Rect({
                        width: this.gridWidth,
                        height: this.gridHeight,
                        left: left,
                        top: top,
                        fill: "#5553",
                        stroke: "grey",
                        selectable: false,

                    });

                    const rowText = new fabric.Text(`Row ${options.row}`, {
                        left: left + 5,
                        top: top + 5,
                        fontSize: 10,
                        stroke: "black",
                        selectable: false,
                    });
                    const colText = new fabric.Text(`Col ${options.col}`, {
                        left: left + 5,
                        top: top + (rowText.height as number) + 5,
                        fontSize: 10,
                        stroke: "black",
                        selectable: false,
                    });

                    const g = new fabric.Group([rect, rowText, colText], this.defaultSelectOption);
                    g.selectable = true;
                    g.excludeFromExport = false;
                    g.data = {
                        panel: true,
                        type: "rect",
                        width: rect.width,
                        height: rect.height,
                        ...options
                    };
                    this.canvas.add(g);
                }

                break;
            case Actions.delete:
                selection.forEach(x => this.canvas.remove(x));
                break;
            case Actions.lock:
                selection.forEach(x => x.selectable = false);
                break;
            case Actions.unlock:
                selection.forEach(x => x.selectable = true);
                break;
            case Actions.toRectangle:
                selection.forEach(x => {

                    if (x.type != "group") return;
                    const g = (x as fabric.Group);

                    const pos = { top: Number(x.top), left: Number(x.left) };
                    if (g.group) {
                        pos.top += Number(g.group.top) + Number(g.group.height) / 2;
                        pos.left += Number(g.group.left) + Number(g.group.width) / 2;
                    }
                    const c = new fabric.Rect({
                        width: x.width,
                        height: x.height,
                        fill: "#5553",
                        stroke: "grey",
                        selectable: false,
                        top: pos.top,
                        left: pos.left,
                        data: x.data
                    });
                    const textPos = {
                        top: Number(pos.top),
                        left: Number(pos.left),
                    };

                    const rowText = new fabric.Text(`Row ${c.data.row}`, {
                        left: textPos.left + 5,
                        top: textPos.top + 5,
                        fontSize: 10,
                        stroke: "black",
                        selectable: false,
                    });
                    const colText = new fabric.Text(`Col ${c.data.col}`, {
                        left: textPos.left + 5,
                        top: textPos.top + (rowText.height as number) + 5,
                        fontSize: 10,
                        stroke: "black",
                        selectable: false,
                    });
                    const newGroupg = new fabric.Group([c, rowText, colText], this.defaultSelectOption);
                    newGroupg.selectable = true;
                    newGroupg.data = x.data;
                    newGroupg.data.type = "rect";
                    this.canvas.add(newGroupg);
                    this.canvas.remove(x);
                });
                break;
            case Actions.toCircle:
                selection.forEach(x => {

                    if (x.type != "group") return;
                    const g = (x as fabric.Group);
                    const textO = g.getObjects("text");
                    const text = (textO[0] as fabric.Text);


                    const pos = { top: Number(x.top), left: Number(x.left) };
                    if (g.group) {
                        pos.top += Number(g.group.top) + Number(g.group.height) / 2;
                        pos.left += Number(g.group.left) + Number(g.group.width) / 2;
                    }
                    const c = new fabric.Circle({
                        radius: Math.min(x.width as number, x.height as number) / 2,
                        fill: "#5553",
                        stroke: "grey",
                        selectable: false,
                        top: pos.top,
                        left: pos.left,
                        data: x.data,
                    });

                    const textPos = {
                        top: Number(pos.top) + Number(c.radius) / 3,
                        left: Number(pos.left) + Number(c.radius) / 3,
                    };

                    const rowText = new fabric.Text(`Row ${c.data.row}`, {
                        left: textPos.left + 5,
                        top: textPos.top + 5,
                        fontSize: 10,
                        stroke: "black",
                        selectable: false,
                    });
                    const colText = new fabric.Text(`Col ${c.data.col}`, {
                        left: textPos.left + 5,
                        top: textPos.top + (rowText.height as number) + 5,
                        fontSize: 10,
                        stroke: "black",
                        selectable: false,
                    });
                    const newGroupg = new fabric.Group([c, rowText, colText], this.defaultSelectOption);
                    newGroupg.selectable = true;
                    newGroupg.data = x.data;
                    newGroupg.data.type = "circle";
                    newGroupg.data.height = c.radius;
                    newGroupg.data.width = c.radius;



                    this.canvas.add(newGroupg);
                    this.canvas.remove(x);
                });
                break;
        }
    }

    public setDragControls() {
        this.canvas.on("mouse:down", (opt) => {
            const evt = opt.e;
            /// middle click
            if (evt.button === 1) {
                evt.preventDefault();
                this.canvas.selection = false;
                this.canvas.discardActiveObject();
                this.isDragging = true;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
                this.viewportTransform = this.canvas.viewportTransform as number[];
            }
            // right click
            if (evt.button === 2) {
                const selection = this.canvas.getActiveObjects();
                const mousePointer = this.canvas.getPointer(evt);
                this.contextMenu({ x: evt.clientX, y: evt.clientY, cx: mousePointer.x, cy: mousePointer.y }, selection);
            }
        });
        this.canvas.on("mouse:move", (opt) => {
            if (this.isDragging) {
                const e = opt.e;
                const vpt = this.viewportTransform as number[];
                vpt[4] += e.clientX - this.lastPosX;
                vpt[5] += e.clientY - this.lastPosY;
                this.canvas.requestRenderAll();
                this.lastPosX = e.clientX;
                this.lastPosY = e.clientY;
            }
        });
        this.canvas.on("mouse:up", (opt) => {
            this.canvas.setViewportTransform(this.viewportTransform as number[]);
            this.isDragging = false;
            this.canvas.selection = true;
        });
    }

    public setZoomControls() {
        this.canvas.on("mouse:wheel", (opt) => {
            const delta = opt.e.deltaY;
            let zoom = this.canvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);

            opt.e.preventDefault();
            opt.e.stopPropagation();
            this.viewportTransform = this.canvas.viewportTransform as number[];
        });
    }

    public setWidth(w: any) {
        this.canvas.setWidth(w);
    }
    public setHeight(h: any) {
        this.canvas.setHeight(h);
    }
    public selectAll() {
        const objects = this.canvas.getObjects().filter(x => x.selectable);
    }
    public deleteSelected() {
        const o = this.canvas.getActiveObjects();
        o.forEach((x) => this.canvas.remove(x));
    }
    public LoadBackground(image: any, backgroundImageSize?: any): Promise<ImageSize> {
        return new Promise((resolve) => {

            fabric.Image.fromURL(image, (img) => {
                const options = {} as fabric.IImageOptions;
                if (!backgroundImageSize) {
                    backgroundImageSize = {
                        width: img.width,
                        height: img.height,
                        scaleX: 1,
                        scaleY: 1,
                    };
                }
                if (backgroundImageSize.scaleX && !backgroundImageSize.width) {
                    backgroundImageSize.width = img.width as number * Number(backgroundImageSize.scaleX);
                }
                if (backgroundImageSize.scaleY && !backgroundImageSize.height) {
                    backgroundImageSize.height = img.height as number * Number(backgroundImageSize.scaleY);
                }

                backgroundImageSize.scaleX = Number(backgroundImageSize.width) / (img.width as number);
                backgroundImageSize.scaleY = Number(backgroundImageSize.height) / (img.height as number);




                options.scaleX = backgroundImageSize.scaleX;
                options.scaleY = backgroundImageSize.scaleY;


                this.currentBackgroundOptions = {
                    ...backgroundImageSize,
                };

                this.canvas.setBackgroundImage(img, (i: any) => {
                    this.canvas.renderAll();
                    return resolve(backgroundImageSize);
                }, options);
            });
        });
    }

    public async resizeBackground(size: any) {
        return await this.LoadBackground(this.backgroundImageDataUrl, size);
    }

    public AddPanel(top: number, left: number, row: number, col: number, width?: number, height?: number) {
        const rowText = new fabric.Text(`Row ${row}`, {
            left: left + 5,
            top: top + 5,
            fontSize: 10,
            stroke: "black",
            selectable: false,
        });
        const colText = new fabric.Text(`Col ${col}`, {
            left: left + 5,
            top: top + 15,
            fontSize: 10,
            stroke: "black",
            selectable: false,
        });
        const rect = new fabric.Rect({
            width: width || this.gridWidth,
            height: height || this.gridHeight,
            left: left,
            top: top,
            fill: "#5553",
            stroke: "grey",
            selectable: false,
        });

        const g = new fabric.Group([rect, rowText, colText], this.defaultSelectOption);
        g.selectable = true;
        g.excludeFromExport = false;
        g.data = {
            panel: true,
            type: "rect",
            row: row,
            col: col,
            width: rect.width,
            height: rect.height,
            icon: "",
        };
        this.canvas.add(g);
    }

}
