export interface INewPanelDefinition {
    startRow: number;
    startColumn: number;
    endRow: number;
    endColumn: number;
    width: number;
    height: number;
    border: number;
}

export class ImageSize {
    width: number;
    height: number;
}


export enum CursorMode {
    Draw,
    Select,
}
export enum Actions {
    delete,
    duplicate,
    toCircle,
    toRectangle,
    lock,
    unlock,
    group,
    ungroup,
    newPanel,
    noAction,
}


export enum EntityType {
    Row,
    Panel,
}
export class SelectedEntity {
    type: EntityType;
    object: fabric.Object;
    top?: number;
    left?: number;
    angle?: number;
    scaleX?: number;
    scaleY?: number;
    height?: number;
    width?: number;

    data?: any;
    constructor(ob: fabric.Object, type: EntityType = EntityType.Panel) {
        this.type = EntityType.Panel;
        this.object = ob;
        this.top = ob.top;
        this.left = ob.left;
        this.angle = ob.angle;
        this.scaleX = ob.scaleX;
        this.scaleY = ob.scaleY;
        this.height = ob.height;
        this.width = ob.width;

        this.data = ob.data;
    }

    ToOption() {
        return {
            top: Number(this.top),
            left: Number(this.left),
            angle: Number(this.angle),
            scaleX: Number(this.scaleX),
            scaleY: Number(this.scaleY),
            height: Number(this.height),
            width: Number(this.width),
        };
    }
}
export class CenterDefinition {

    top: number;
    left: number;
    row: number;
    col: number;
    height: number;
    width: number;
    type: string;
    angle: number;

}
export class OnedropFileFormat {

    data: string;
    background: string;
    options: fabric.IImageOptions;
    centers: CenterDefinition[];
}