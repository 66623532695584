import Vue from 'vue';
import App from './App.vue';

import { router } from './router';
import '@mdi/font/css/materialdesignicons.css';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts';
import BlankLayout from '@/layouts/Blank.vue';
import ContentLayout from '@/layouts/Content.vue';
import DefaultLayout from '@/layouts/Default.vue';
import VueAuthenticate from "vue-authenticate";
import VueMoment from "vue-moment";
import VueAppInsights from "vue-application-insights";
import DatetimePicker from "vuetify-datetime-picker";

import VueWorker from 'vue-worker';


import moment from 'moment';
import VueAxios from "vue-axios";
import axios from "axios";
import Gravatar from 'vue-gravatar';
import { coreComponent } from './core/components/core.module';


Vue.component('v-gravatar', Gravatar);

Vue.config.productionTip = false;

Vue.component('apexchart', VueApexCharts);
Vue.component('blank-layout', BlankLayout);
Vue.component('content-layout', ContentLayout);
Vue.component('default-layout', DefaultLayout);


Vue.filter('formatDate', (value: string) => {
  if (value) {
    return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm');
  }
  return "--/--/-- --:--";
});

Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_WEBAPI_URL,
  loginUrl: "auth/login",
  tokenPrefix: "auth",
  tokenName: "token",
});


Vue.use(VueApexCharts);
Vue.use(VueMoment);
Vue.use(DatetimePicker);
Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
  router,
});
Vue.use(VueWorker);
Vue.use(coreComponent);
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');

