








import { Component } from "vue-property-decorator";

import AppVue from "./AppVue.vue";

@Component
export default class App extends AppVue {
  get layout() {
    return (this.$route.meta?.layout || "default") + "-layout";
  }
  async created() {}
  mounted() {}
  destroyed() {}
}
