




// eslint-disable-next-line object-curly-newline

import { Component, Vue } from "vue-property-decorator";

@Component
export default class Logout extends Vue {
  mounted() {
    this.$auth.logout();
    this.$router.push("/");
  }
}
