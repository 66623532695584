import { AppModule, AppRouteConfig } from "@/core/appTypes";

import DeviceList from './DeviceList.vue';
import DeviceDetail from './DeviceDetail.vue';

import * as icons from "@mdi/js";
export const deviceModule: AppModule = {
    routes: [
        {
            path: '/devices',
            name: 'Device List',
            label: 'Devices',
            icon: icons.mdiDevices,
            visible: true,
            component: DeviceList,
            order: 30
        },
        {
            path: '/device/:id?',
            name: 'DeviceDetail',
            label: 'Device',
            icon: icons.mdiDevices,
            visible: false,
            component: DeviceDetail,
            order: 30
        },
    ]
};