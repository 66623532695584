






























































































































































































































/* eslint-disable no-unreachable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { fabric } from "fabric";
import HotkeysCpt, { HotkeyInputModel } from "@/components/hotkeys/hotkeys.vue";
import * as icons from "@mdi/js";
import { WetmapEditor } from "./WetmapEditor";
import AddNodesButton from "./AddNodes.vue";
import { contextMenuDefinition, INewPanelDefinition } from "./";
import {
  Actions,
  EntityType,
  OnedropFileFormat,
  SelectedEntity,
} from "./EditorInterfaces";
import AddNodeDialog from "./AddNodeDialog.vue";
import EntityDetailsEditor from "./EntityDetailsEditor.vue";
import JSZip from "jszip";

@Component({
  components: {
    HotkeysCpt: () => Promise.resolve(HotkeysCpt),
    AddNodesButton: () => Promise.resolve(AddNodesButton),
    AddNodeDialog: () => Promise.resolve(AddNodeDialog),
    EntityDetailsEditor: () => Promise.resolve(EntityDetailsEditor),
  },
})
export default class Editor extends Vue {
  @Prop({ default: "" }) stringSettings: string;
  @Prop({ default: "" }) background: string;
  @Prop({ default: "" }) backgroundOptions: any;
  @Prop({ default: false }) isDialog: boolean;
  backgroundFile: File = {} as File;
  row: number = 0;
  col: number = 0;
  selectedEntity: SelectedEntity = new SelectedEntity(
    new fabric.Object({ data: { type: "empty" } }),
  );
  dialogResize = false;
  addNodesDialog = false;
  backgroundDimensions = {
    width: 100,
    height: 100,
  };
  icons = icons;
  data: any[] = [];
  scale = 0.005;
  canvasElement: HTMLCanvasElement;
  canvas: WetmapEditor = {} as WetmapEditor;
  x = 0;
  y = 0;
  items = contextMenuDefinition;
  backgroundRules = [
    (value: any) =>
      !value ||
      value.size < 2000000 ||
      "Background size should be less than 2 MB!",
  ];
  hotkeys: HotkeyInputModel[] = [
    {
      hotkey: "Delete",
      handler: () => {
        this.delete();
      },
    },
    {
      hotkey: "CTRL+a",
      handler: () => {
        this.selectAll();
      },
    },
    {
      hotkey: "CTRL+0",
      handler: () => {
        this.resetZoom();
      },
    },
  ];
  showMenu = false;
  currentSelection: any[] = [];
  currentContextPosition: any = {};
  objects: any[] = [];
  selectedObjects: number[] = [];

  get editorCols() {
    if (!this.objects.length) return {};
    const d = this.objects.filter((x) => x.data && x.data.panel);

    return d.reduce((r, x, i) => {
      const col = x.data.col;
      const element = {
        id: i,
        name: `Row ${x.data.row} Col ${x.data.col}`,
        row: x.data.row,
        col: x.data.col,
        canvasObject: x,
        icon: x.data.type == "rect" ? "mdi-rectangle" : "mdi-circle",
      };

      if (
        this.searchString == "" ||
        element.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
      ) {
        r[col] = r[col] || [];
        r[col].push(element);
      }

      return r;
    }, {});
  }
  get editorRows() {
    if (!this.objects.length) return {};
    const d = this.objects.filter((x) => x.data && x.data.panel);

    return d.reduce((r, x, i) => {
      const row = x.data.row;
      const element = {
        id: i,
        name: `Row ${x.data.row} Col ${x.data.col}`,
        row: x.data.row,
        col: x.data.col,
        canvasObject: x,
        icon: x.data.type == "rect" ? "mdi-rectangle" : "mdi-circle",
      };

      if (
        this.searchString == "" ||
        element.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
      ) {
        r[row] = r[row] || [];
        r[row].push(element);
      }

      return r;
    }, {});
  }
  get editorObjects() {
    if (!this.objects.length) return this.objects;
    const d = this.objects.filter((x) => x.data && x.data.panel);
    if (!d.length) return [];
    return d
      .map((x, i) => ({
        id: i,
        name: `Row ${x.data.row} Col ${x.data.col}`,
        row: x.data.row,
        col: x.data.col,
        canvasObject: x,
        icon: x.data.type == "rect" ? "mdi-rectangle" : "mdi-circle",
      }))
      .filter(
        (item) =>
          this.searchString == "" ||
          item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1,
      )
      .sort((a, b) => a.row * 10 + a.col - (b.row * 10 + b.col));
  }
  get itemsForSelection() {
    return this.items.filter((x) => {
      if (x.empty && !x.selection && this.currentSelection.length == 0)
        return true;
      if (x.empty && !x.selection && this.currentSelection.length > 0) {
        return false;
      }
      if (x.selection && this.currentSelection.length > 0) {
        if (x.type) {
          return (
            this.currentSelection.filter(
              (s) => s.data.panel && s.data.type == x.type,
            ).length > 0
          );
        }
        return true;
      }
      return false;
    });
  }

  updateBackgroundDialog = false;
  updateBackground() {
    this.updateBackgroundDialog = true;
  }
  saveBackground() {
    this.updateBackgroundDialog = false;
  }
  searchString: string = "";

  selectObject(x: any) {
    this.canvas.canvas.discardActiveObject();
    var r = this.canvas.canvas
      .getObjects()
      .filter((o) => o.data.panel)
      .filter((o) => x.row == o.data.row && x.col == o.data.col);
    if (r.length == 0) {
      return;
    }
    const selectedElement = r[0];
    selectedElement.setOptions({
      borderColor: "blue",
      cornerColor: "blue",
      cornerStrokeColor: "blue",
      cornerSize: 10,
    });

    this.selectedEntity = new SelectedEntity(selectedElement, EntityType.Panel);
    this.canvas.canvas.setActiveObject(selectedElement).requestRenderAll();
    this.canvas.renderAll();
  }
  selectObjectList(values: any[]) {
    this.canvas.canvas.discardActiveObject();
    const panels = this.canvas.canvas
      .getObjects()
      .filter((o) => o.data && o.data.panel);
    const objectsToSelect: fabric.Object[] = [];
    values.forEach((x) => {
      panels
        .filter((o) => x.row == o.data.row && x.col == o.data.col)
        .forEach((item) => {
          if (item) {
            (item as any).set("active", true);
            item.setOptions({
              borderColor: "blue",
              cornerColor: "blue",
              cornerStrokeColor: "blue",
              cornerSize: 10,
            });
            objectsToSelect.push(item);
          }
        });
    });
    const selection = new fabric.ActiveSelection(objectsToSelect, {
      canvas: this.canvas.canvas,
      selectionBackgroundColor: "blue",
      borderColor: "blue",
      backgroundColor: "blue",
      cornerColor: "blue",
      cornerStrokeColor: "blue",
      transparentCorners: false,
      cornerSize: 10,
    });

    this.selectedEntity = new SelectedEntity(selection, EntityType.Row);
    this.canvas.canvas.setActiveObject(selection).requestRenderAll();
  }

  updateSelectedEntity(entity: SelectedEntity) {
    this.selectedEntity.object.set(entity.ToOption());
    this.selectedEntity.object.data = entity.data;
    this.selectedEntity.object.setCoords();
    this.selectedEntity = new SelectedEntity(entity.object);
    this.$nextTick(() => {
      this.canvas.canvas.requestRenderAll();
    });
  }
  compareObject(a: any, b: any) {
    if (a.id > -1 && b.id > -1) return a.id == b.id;
    return a == b.id;
  }
  async created() {
    for (var i = 0; i < 46 * 46; i++) {
      const val = Math.abs(Math.cos(i * this.scale)) * 100;
      this.data.push(val);
    }
    window.addEventListener("resize", this.resize);
  }
  previewBackgroundImage = "";
  previewBackgroundImageSize = { width: 0, height: 0 };
  async previewImagefromString(imageString: string) {
    const dimensions: any = await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = imageString;
    });
    this.previewBackgroundImageSize.width = dimensions.width as number;
    this.previewBackgroundImageSize.height = dimensions.height as number;
  }
  async previewImage(evt: File) {
    this.previewBackgroundImage = URL.createObjectURL(evt);
    await this.previewImagefromString(this.previewBackgroundImage);
  }
  updateBackgroundHeight(evt: any) {
    this.backgroundDimensions.height =
      (this.backgroundDimensions.width /
        this.previewBackgroundImageSize.width) *
      this.previewBackgroundImageSize.height;
  }
  updateBackgroundWidth() {
    this.backgroundDimensions.width =
      (this.backgroundDimensions.height /
        this.previewBackgroundImageSize.height) *
      this.previewBackgroundImageSize.width;
  }
  async backgroundUpload(evt: File, backgroundDimensions: any) {
    this.updateBackgroundDialog = false;
    if (evt.name) {
      await this.canvas.uploadBackgroundImage(evt);
    }
    const img = await this.canvas.resizeBackground(backgroundDimensions);
    this.backgroundDimensions.width = img.width as number;
    this.backgroundDimensions.height = img.height as number;
  }
  async resizeBackground(backgroundDimensions: any) {
    this.dialogResize = false;
    const img = await this.canvas.resizeBackground(backgroundDimensions);
    this.backgroundDimensions.width = img.width as number;
    this.backgroundDimensions.height = img.height as number;
  }
  destroyed() {
    window.removeEventListener("resize", this.resize);

    this.canvas.canvas.off("object:modified", this.objectUpdate);
    this.canvas.canvas.off("object:added", this.objectUpdate);
    this.canvas.canvas.off("object:removed", this.objectUpdate);
    this.canvas.canvas.off("object:loaded", this.objectUpdate);
    this.canvas.canvas.off("selection:created", this.objectSelected);
    this.canvas.canvas.off("selection:updated", this.objectSelected);
    this.canvas.canvas.off("selection:cleared", this.objectSelected);
  }

  async importPlan() {
    const c = document.createElement("input");
    c.setAttribute("type", "file");
    c.setAttribute("name", "plan");
    c.setAttribute("accept", ".aqp");
    c.addEventListener("change", async (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement.files && inputElement.files.length) {
        const uploadedFile = inputElement.files[0];
        var reader = new FileReader();
        reader.onload = async (e) => {
          var new_zip = new JSZip();

          const arr = e.target?.result as ArrayBuffer;
          new_zip.loadAsync(arr).then((zip) => {
            const file = zip
              .file("plan.txt")
              ?.async("string")
              .then((planContent: string) => {
                const importedFile = JSON.parse(
                  planContent,
                ) as OnedropFileFormat;
                this.canvas.load(importedFile.data);
                this.canvas.LoadBackgroundDataUrl(
                  importedFile.background,
                  importedFile.options,
                );
              });
          });
        };
        reader.readAsArrayBuffer(uploadedFile);
        document.body.removeChild(c);
      }
    });
    document.body.appendChild(c);
    c.click();
  }

  async exportPlan() {
    const saveData = this.canvas.save() as OnedropFileFormat;
    const stringDataFile = JSON.stringify(saveData);
    var zip = new JSZip();
    zip.file("plan.txt", stringDataFile);
    zip.generateAsync({ type: "blob" }).then((blob) => {
      const a = document.createElement("a");
      document.body.appendChild(a);

      const url = window.URL.createObjectURL(blob);
      a.setAttribute("href", url);
      a.setAttribute("style", "display:none;");
      a.setAttribute("download", "plan.aqp");
      a.click();
      document.body.removeChild(a);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 2000);
    });
  }
  save() {
    this.$emit("close", this.canvas.save());
  }
  close() {
    this.$emit("close", "");
  }

  async resize() {
    const parent = this.canvasElement.parentElement?.parentElement;

    this.$nextTick(() => {
      this.canvas.setWidth(
        (parent?.clientWidth as number) || window.innerWidth,
      );
      this.canvas.setHeight(parent?.clientHeight || window.innerHeight);
      this.canvas.canvas.requestRenderAll();
    });
  }
  addNodes(model: INewPanelDefinition) {
    for (var r = model.startRow; r <= model.endRow; r++) {
      for (var c = model.startColumn; c <= model.endColumn; c++) {
        this.canvas.AddPanel(
          0 + r * model.height + r * model.border,
          0 + c * model.width + c * model.border,
          r,
          c,
          model.width,
          model.height,
        );
      }
    }
  }
  async mounted() {
    this.canvasElement = this.$refs.canvasRef as HTMLCanvasElement;
    // let's mount it fullscreen

    this.canvas = new WetmapEditor(this.canvasElement);
    this.canvas.setContextMenu(this.contextMenu);
    this.canvas.canvas.on("selection:created", this.objectSelected);
    this.canvas.canvas.on("selection:updated", this.objectSelected);
    this.canvas.canvas.on("selection:cleared", this.objectSelected);

    this.canvas.canvas.on("object:modified", this.objectUpdate);
    this.canvas.canvas.on("object:added", this.objectUpdate);
    this.canvas.canvas.on("object:removed", this.objectUpdate);
    this.canvas.canvas.on("object:loaded", this.objectUpdate);
    this.resize();
    if (this.stringSettings) {
      const sett = this.stringSettings;
      await this.canvas.load(sett);
      const img = await this.canvas.LoadBackgroundDataUrl(
        this.background,
        this.backgroundOptions,
      );

      this.previewBackgroundImage = this.background;
      this.previewImagefromString(this.previewBackgroundImage);
      this.backgroundDimensions.width = img.width as number;
      this.backgroundDimensions.height = img.height as number;
    }
  }
  objectUpdate() {
    this.objects = this.canvas.canvas.getObjects();
    var sel = this.canvas.canvas.getActiveObject();
    if (sel) this.selectedEntity = new SelectedEntity(sel);
  }
  objectSelected(ev: any) {
    const selection = this.canvas.canvas.getActiveObject();
    if (selection) {
      this.selectedEntity = new SelectedEntity(selection);
    } else {
      this.selectedEntity = new SelectedEntity(
        new fabric.Object({ data: { type: "empty" } }),
      );
    }
    const e = ev.e as MouseEvent;
    const ob = ev.selected as fabric.Object[];
    if (!ob || ob.filter((x) => x.data).length == 0) {
      return;
    }
    this.selectedObjects = [];
    const s = this.editorObjects;
    if (!ob) return;
    ob.filter((x) => x.data && x.data.panel).forEach((item) => {
      this.selectedObjects.push(item.data);
      const d = item.data;
      const idx = s.findIndex((r) => d.row == r.row && d.col == r.col);
      if (idx > -1) {
        this.selectedObjects.push(s[idx]);
      }
    });
  }

  rowColDialog = false;
  rowColDialogOk: Function = () => {};
  async askForRowCol() {
    this.rowColDialog = true;
    return new Promise((resolve, reject) => {
      this.rowColDialogOk = (rowCol: any) => {
        this.rowColDialog = false;
        this.rowColDialogOk = () => {};
        resolve(rowCol);
      };
    });
  }

  async contextClick(action: Actions, items: any[]) {
    let options = {};
    if (action == Actions.newPanel) {
      const rowCol: any = await this.askForRowCol();
      if (!rowCol) return;
      options = {
        row: Number(rowCol.row),
        col: Number(rowCol.col),
      };
    }
    this.canvas.executeAction(
      action,
      items,
      this.currentContextPosition,
      options,
    );
  }
  contextMenu(mousePos: any, items: any[]) {
    this.showMenu = false;
    this.currentSelection = items;
    this.currentContextPosition = mousePos;
    this.x = mousePos.x;
    this.y = mousePos.y;
    this.$nextTick(() => {
      this.showMenu = true;
    });
  }
  resetZoom() {
    this.canvas.resetZoom();
  }
  selectAll() {
    this.canvas.selectAll();
  }
  delete() {
    this.canvas.deleteSelected();
  }
}
